import React, { HTMLAttributes, PropsWithChildren } from 'react'
import { ItemContent } from './styled-components'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
}

function DevToolItemContent(props: Props) {
  return (
    <ItemContent {...props}/>
  )
}

export { DevToolItemContent }
