import { AxiosRequestConfig } from 'axios';
import formatDateTime from 'date-fns/format';
import { getKSTNow } from '../../../common';
import {
  BaseCarInfo,
  OuterCarInfoResponseData,
  OuterCarInfoResultCode,
} from '../../../esc-proxy';
import { frontmanAxios } from '../../axios';

export interface GetOuterCarInfoParams extends BaseCarInfo {
  reqTime?: number;
}

/**
 * 차량 정보 외부 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPNDDS/pages/341938922/ESC+1.1.14.+-+-+V2?atlOrigin=eyJpIjoiYTZmMzZiYWRkYTJjNGQ1NGIxYmYwNDhkMTc5NzU0Y2IiLCJwIjoiYyJ9
 * @param {GetOuterCarInfoParams} props
 * @param config
 */
function getOuterCarInfo(
  props: GetOuterCarInfoParams,
  config: AxiosRequestConfig = {}
) {
  const { carNumber, ownerName, reqTime } = props;
  const { headers: configHeaders, ...restConfig } = config;

  let reqTimeout = 30000;
  if (reqTime && !isNaN(reqTime)) {
    reqTimeout = Number(reqTime);
  }
  const requestTime = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss');
  return frontmanAxios.post<OuterCarInfoResponseData>(
    '/esc/esc-channel/profile/outercarinfo/find/carinfo/v2',
    {
      header: {
        reqTime: requestTime,
      },
      carInfo: {
        carNumber,
        ownerName,
      },
    },
    {
      ...restConfig,
      headers: {
        Accept: 'application/json',
        ...configHeaders,
      },
      timeout: reqTimeout,
    }
  );
}

function isOuterCarInfoRequestSucceed(code: OuterCarInfoResultCode) {
  return code === '0' || code === '000000';
}

export { getOuterCarInfo, isOuterCarInfoRequestSucceed };
