import last from 'lodash/last'
import React, { FC, Suspense, useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import {
  BaseDialogComponentProps,
  DialogItem,
  DialogManagerListener,
  DialogManagerOptions,
  DialogOptions,
  DialogParams,
  HistoryAction,
  HistoryBlockHook,
  HistoryChangeListener,
  HistoryLocation,
} from './types'

type OnBlockHistoryResult = 'PASS' | 'BLOCKED' | 'DIALOG_HISTORY_POPPED'

const DIALOG_MANAGER_HISTORY_MARKER_KEY = '_dialog_manager_history_marker'

class DialogManager<Action extends HistoryAction, State = any> {
  // 다이얼로그 제거 작업이 완전히 끝난후 popstate 이벤트에서 onClosedByXXX 리스너를 호출해주기 위한 대기큐.
  // showDialog 참고.
  deferredOnClosedCallbacks: (() => void)[] = []

  // 다이얼로그가 사용자의 ok/cancel/outside click 액션으로 닫힌 경우,
  // popstate 이벤트에서 다이얼로그를 제거할때 onClosedByDismiss 호출하지 않도록 거르기 위해 여기 저장.
  closedDialogsByAction: DialogItem[] = []

  dialogItemList: DialogItem[] = []
  historyListenerList: HistoryChangeListener<Action, State>[] = []
  historyBlockHookList: HistoryBlockHook<Action, State>[] = []

  listeners: DialogManagerListener;

  constructor(options: DialogManagerOptions) {
    this.listeners = {
      onDialogListChanged: options.onDialogListChanged,
      onNeedHistoryBack: options.onNeedHistoryBack,
      onNeedHistoryPush: options.onNeedHistoryPush,
    }
  }

  /**
   * 주의사항
   *
   * 1. 다이얼로그 액션 리스너는 onOk, onCancel 로 고정.
   * onOk, onCancel 의 리턴값이 undefined/true 이면 다이얼로그를 닫고 false 면 닫지 않는다.
   *
   * 2. react-router history.block
   * history.block을 사용하지 말고 useBlockHistory 사용할것.
   * src/hooks.tsx#useBlockHistory 설명참고.
   *
   * 3. history.push/replace/back
   *  다이얼로그 onOk, onCancel 리스너에서 history.push/replace/back 호출하지 말고 대신 아래 옵션을 사용할것.
   * - onClosedByOk
   * - onClosedByCancel
   *
   * 다이얼로그를 닫고 페이지 네비게이션을 원할때, 정상 작동을 보장하기 위해 popstate이벤트 발생했을때 호출하는 onClosedByXXX 리스너를 따로둠.
   * onOk 내부에서 history.push 호출한 경우를 예로들면,
   * onOk(history.back(다이얼로그 닫힘)) > history.push(페이지 이동) 순서로 호출하므로 호출한 순서로 동작할 것 같지만,
   * 다이얼로그를 닫을때 호출하는 history.back의 popstate이벤트가 발생하기전에 history.push/replace/back을 호출한다.
   * 호출한 순서와 달리 onOk(history.back(다이얼로그 닫힘)) > history.push > popstate 이벤트 순서로 동작한다.
   * popstate 이벤트가 언제 발생할지 알수없기 때문에 setTimeout으로 비동기 호출해서 해결 할수없다.
   * 참고.
   * https://www.w3.org/TR/2011/WD-html5-20110113/history.html#the-history-interface
   * This ensures that the popstate event that will be fired when the document finally loads will accurately reflect the pushed or replaced state object.
   *
   * 3. iOS에서 focus 수동 호출로 키보드 보여주기
   * showDialog호출한 태스크(이벤트 루프의 태스크)와 다이얼로그 렌더링 태스크가 다를때 내부 input에 포커스를 넣으려면 useInputFocusHack: true 옵션을 추가한다.
   * iOS에서만 사용하고 Android는 사용할 필요앖다.
   *
   * iOS는 사용자 인터랙션으로 호출된 이벤트 리스너 실행과 같은 태스크에서 input focus메소드를 호출해야 동작한다.
   * suspence를 사용해 다이얼로그 컴포넌트를 렌더링하면 showDialogo와 다른 태스크에서 useEffect훅 호출을 하게되어 input focus 메소드 호출을 무시한다.
   * 포커스를 넣기위해 편법을 사용한다.
   * 이벤트 리스너에서 안보이는 input을 어딘가 넣고 포커스를 준다. (키보드 나타남)
   * 목적 input엘리먼트의 focus메소드를 호출해서 포커스를 옮겨준다.
   * 이 방법은 키보드가 나타날때 input위치로 강제 스크롤하는 위치가 부정확 할 수 있다.
   * 참고.
   * https://gist.github.com/cathyxz/73739c1bdea7d7011abb236541dc9aaa
   * https://stackoverflow.com/a/55652503
   */
  showDialog<Props extends BaseDialogComponentProps<any>>(
    dialogParams: DialogParams<Props>,
    dialogOptions: DialogOptions<Props> = {}
  ) {
    const {
      useHistory = true,
      useInputFocusHack = false,
      useCloseOnOutsideClick = true,
      onClosedByOk,
      onClosedByCancel,
      onClosedByDismiss,
    } = dialogOptions
    const dialogId = uuid()
    const close = (onClosed?: (...args: any) => void, ...args: any) => {
      this.closeDialog(dialogId)
      if (onClosed) {
        if (useHistory) {
          this.deferredOnClosedCallbacks.push(() => onClosed(...args))
        } else {
          onClosed(...args)
        }
      }
    }
    const DialogWrapperComponent: FC<Props> = (props) => {
      const refWrapper = useRef<HTMLDivElement>(null)
      const refDialogBackground = useRef<HTMLElement>(null)

      useEffect(() => {
        if (!useCloseOnOutsideClick) {
          return
        }

        const handleClick = (e: MouseEvent) => {
          const backgroundElement = refDialogBackground.current || refWrapper.current?.firstElementChild as HTMLElement
          if (e.target === backgroundElement) {
            // 연타로 반복 호출이 발생하지 않도록 클릭 핸들러 삭제.
            removeClickHandler()
            this.closeDialog(dialogId)
            if (onClosedByDismiss) {
              if (useHistory) {
                this.deferredOnClosedCallbacks.push(() => onClosedByDismiss('OUTSIDE'))
              } else {
                onClosedByDismiss('OUTSIDE')
              }
            }
          }
        }
        const removeClickHandler = () => {
          refWrapper.current?.removeEventListener('click', handleClick)
        }

        refWrapper.current?.addEventListener('click', handleClick)
        return () => {
          removeClickHandler()
        }
      }, [])

      return (
        <div ref={refWrapper}>
          <Suspense fallback="">
            <dialogParams.component {...props} backgroundRef={refDialogBackground}/>
          </Suspense>
        </div>
      )
    }
    DialogWrapperComponent.displayName = `DialogWrapperComponent(${dialogId})`

    const dialogItem: DialogItem<Props> = {
      id: dialogId,
      dialogParams: {
        component: DialogWrapperComponent,
        props: Object.assign({}, dialogParams.props, {
          onOk: async (...args: any) => {
            const ret = await dialogParams.props?.onOk?.(...args)
            if (ret !== false) {
              close(onClosedByOk, ...args)
            }
          },
          onCancel: async (...args: any) => {
            const ret = await dialogParams.props?.onCancel?.(...args)
            if (ret !== false) {
              close(onClosedByCancel, ...args)
            }
          },
        }),
      },
      dialogOptions: {
        ...dialogOptions,
        useHistory,
        useInputFocusHack,
        useCloseOnOutsideClick,
      },
    }

    this.addDialog(dialogItem)

    if (useHistory) {
      this.listeners.onNeedHistoryPush(dialogId)
    }

    if (useInputFocusHack) {
      const fakeInput = document.createElement('input')
      fakeInput.id = uuid()
      fakeInput.type = 'text'
      fakeInput.style.position = 'absolute'
      fakeInput.style.height = '0'
      fakeInput.style.opacity = '0'
      // fakeInput.style.top = `${getRootScrollingElement().scrollTop}px`
      document.body.appendChild(fakeInput)
      fakeInput.focus()
      const handleRemove = (e: FocusEvent) => {
        if ((e.target as Element).id === fakeInput.id) {
          document.body.removeChild(fakeInput)
          document.removeEventListener('blur', handleRemove, true)
        }
      }
      document.addEventListener('blur', handleRemove, true)
    }

    return dialogId
  }

  closeDialog(dialogId: string) {
    const dialogItem = this.dialogItemList.find(({ id }) => id === dialogId)
    if (dialogItem) {
      if (dialogItem.dialogOptions.useHistory) {
        this.closedDialogsByAction.push(dialogItem)
        this.listeners.onNeedHistoryBack()
      } else {
        this.removeDialog(dialogId)
      }
    }
  }

  listenHistory(listener: HistoryChangeListener<Action, State>) {
    const unregister = () => {
      const index = this.historyListenerList.findIndex(l => l === listener)
      this.historyListenerList.splice(index, 1)
    }
    this.historyListenerList.push(listener)
    return unregister
  }

  blockHistory(hook: HistoryBlockHook<Action, State>) {
    const unregister = () => {
      const index = this.historyBlockHookList.findIndex(h => h === hook)
      this.historyBlockHookList.splice(index, 1)
    }
    this.historyBlockHookList.push(hook)
    return unregister
  }

  // 여기부터 외부 패키지에서 호출금지 메소드

  onChangeHistory(location: HistoryLocation<State & { [DIALOG_MANAGER_HISTORY_MARKER_KEY]?: string; }>, action: Action) {
    if (action !== 'POP' && location.state?.[DIALOG_MANAGER_HISTORY_MARKER_KEY]) {
      return
    }

    for (let index = this.historyListenerList.length - 1; index > -1; index--) {
      this.historyListenerList[index](location, action)
    }
  }

  onBlockHistory(location: HistoryLocation<State & { [DIALOG_MANAGER_HISTORY_MARKER_KEY]?: string; }>, action: Action): OnBlockHistoryResult {
    if (action !== 'POP' && location.state?.[DIALOG_MANAGER_HISTORY_MARKER_KEY]) {
      return 'PASS'
    }

    if (action === 'POP'){
      const onClosed = this.deferredOnClosedCallbacks.shift()
      if (onClosed) {
        // popstate이벤트를 받은 시점은 url만 바뀌고 dom은 현재 history에 맞게 반영되지 않았을수있다.
        // document의 현재 history 반영 완료를 보장하기 위해 호출을 다음 틱으로 미룬다.
        // 참고.
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event#the_history_stack
        setTimeout(onClosed)
      }

      // 사용자의 ok/cancel/outside click 액션으로 닫힌 다이얼로그가 있다면 not null.
      let dialogItem = this.closedDialogsByAction.shift()
      // back버튼으로 닫힌 다이얼로그가 있다면 not null.
      let onClosedByDismiss
      if (!dialogItem) {
        // 열려있는 다이얼로그를 브라우저 back 눌러서 발생한 popstate이벤트에서 닫아야 하는 경우.
        dialogItem = last(this.dialogItemList)
        onClosedByDismiss = dialogItem?.dialogOptions.onClosedByDismiss
      }
      if (dialogItem) {
        this.removeDialog(dialogItem.id)
        onClosedByDismiss?.('POPSTATE')
        if (dialogItem.dialogOptions.useHistory) {
          return 'DIALOG_HISTORY_POPPED'
        }
      }
    }

    let blocked = false
    for (let index = this.historyBlockHookList.length - 1; index > -1; index--) {
      const result = this.historyBlockHookList[index](location, action)
      if (result) {
        blocked = true
      }
    }
    return blocked ? 'BLOCKED' : 'PASS'
  }

  private addDialog(dialogItem: DialogItem) {
    this.listeners.onDialogListChanged(this.dialogItemList.concat(dialogItem))
    this.dialogItemList.push(dialogItem)
  }

  private removeDialog(dialogId: string) {
    const index = this.dialogItemList.findIndex(({ id }) => id === dialogId)
    this.listeners.onDialogListChanged(this.dialogItemList.slice(0, index).concat(this.dialogItemList.slice(index + 1)))
    this.dialogItemList.splice(index, 1)
  }
}

export {
  DialogManager,
  DIALOG_MANAGER_HISTORY_MARKER_KEY,
}
