import { AxiosRequestConfig } from 'axios';
import { CarProfileListResData } from '../../../common';
import { frontmanAxios } from '../../axios';

/**
 * 차량 프로필 조회
 * @see https://tmobi.atlassian.net/l/cp/1jop147M
 */
function getCarProfileList(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<CarProfileListResData>(
    '/flerken/api/v3/car-info/$userKey',
    config
  );
}

export { getCarProfileList };
