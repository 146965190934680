import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { UbdsApiStatusResponse, SimpleTotalSafeDrivingInfo } from './types'

/**
 * 안전운전 정보 조회 (종합 안전운전 정보 조회 심플 버전)
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/723517441
 * @param config
 */

export interface SimpleTotalSafeDrivingInfoResData extends UbdsApiStatusResponse {
  data: SimpleTotalSafeDrivingInfo
}

function getSimpleSafeDrivingInfo(config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.post<SimpleTotalSafeDrivingInfoResData>('/ubds/trip-report/v1/channel/ubds-rmi/RestApi/SafeDrivingService/findSimpleSafeDrivingInfo', {
    userKey: '$userKey'
  }, {
    ...restConfig,
    headers: {
      'Body-Replace': true,
      ...configHeaders
    }
  })
}

export { getSimpleSafeDrivingInfo }
