import React, { HTMLAttributes, PropsWithChildren } from 'react'
import { useConfig } from '../providers'
import { DefaultPanel } from './styled-components'

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>>

export function DevToolDefaultPanel(props: Props) {
  const config = useConfig()
  return <DefaultPanel {...props}  baseBackgroundColor={config?.baseBackgroundColor} />
}
