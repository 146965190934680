import { AxiosRequestConfig } from 'axios'
import { UserMessageExpireReqType } from './types'
import { frontmanAxios } from '../../axios'

/**
 * 사용자 메시지 표출 상태 만료
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/561874581
 * @param params
 * @param config
 */

function updateUserMessageExpire(params: UserMessageExpireReqType, config: AxiosRequestConfig = {}) {
  return frontmanAxios.post<void>('/driving-score/msg/message/$userKey/display-states/expire', params, config)
}

export { updateUserMessageExpire }
