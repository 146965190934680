import { RefObject, useCallback } from 'react'
import { useConfig } from '../providers'

function useCopyNodeText<E extends HTMLElement>(refNode?: RefObject<E> | null) {
  const webViewConfig = useConfig()
  return useCallback(async () => {
    if (!refNode?.current) {
      return
    }
    let text
    if (refNode.current.tagName === 'INPUT' || refNode.current.tagName === 'TEXTAREA') {
      text = (refNode.current as any).value
    } else {
      text = refNode.current.textContent || ''
    }

    if (webViewConfig?.onCopyText) {
      webViewConfig.onCopyText(text)
      webViewConfig?.onToastMessage?.('클립보드에 저장했습니다.')
    } else {
      try {
        await navigator.clipboard.writeText(text)
        webViewConfig?.onToastMessage?.('클립보드에 저장했습니다.')
      } catch {
        const textNode = document.createElement('textarea')
        textNode.value = text
        textNode.style.position = 'absolute'
        textNode.style.width = '0'
        textNode.style.height = '0'
        textNode.style.visibility = 'none'
        document.body.appendChild(textNode)

        // for safari
        const range = document.createRange()
        range.selectNode(textNode)
        document.getSelection()?.removeAllRanges()
        document.getSelection()?.addRange(range)
        textNode.setSelectionRange(0, textNode.value.length)

        // for others
        textNode.select()

        const result = document.execCommand('copy')
        document.body.removeChild(textNode)
        if (result) {
          webViewConfig?.onToastMessage?.('클립보드에 저장했습니다.')
        } else {
          webViewConfig?.onToastMessage?.('클립보드 저장에 실패했습니다.')
        }
      }
    }
  }, [refNode])
}

export { useCopyNodeText }
