import { AxiosRequestConfig } from 'axios';
import { frontmanAxios } from '../../axios';
import { BoardListDataType } from '../../../common';

export interface BoardListParams {
  boardCode: string;
  pageNo?: number;
  pageSize?: number;
  boardStatus?: string | null;
}

/**
 * 공통 컨텐츠 게시판 목록 조회
 * @see https://tmobi.atlassian.net/l/cp/Y16Cj5pp
 * @param isProxy
 * @param params
 * @param config
 */
function getBoardList(
  isProxy: boolean,
  params: BoardListParams,
  config: AxiosRequestConfig = {}
) {
  const { boardCode, pageNo, pageSize, boardStatus } = params;
  
  const baseURL = isProxy ? '/proxy/square-admin/user/api/boards/' : '/square-admin/user/api/boards/'
  let boardParams = '';
  if (pageNo || pageSize || boardStatus) {
    boardParams = '?';
  }
  if (pageNo && pageNo > 0) {
    boardParams += `pageNo=${pageNo}`;
  }
  if (pageSize && pageSize > 0) {
    boardParams += `&pageSize=${pageSize}`;
  }
  if (boardStatus) {
    boardParams += `&boardStatus=${boardStatus}`;
  }
  
  return frontmanAxios.get<BoardListDataType>(
    `${baseURL}${boardCode}${boardParams}`,
    config
  );
}

export { getBoardList };
