import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import formatDateTime from 'date-fns/format'
import { EscApiBody, getKSTNow } from '../../../common'

/**
 * 즐겨찾는 경로 등록
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/244880786/08.#1.-%EC%A6%90%EA%B2%A8%EC%B0%BE%EB%8A%94-%EA%B2%BD%EB%A1%9C-%EB%93%B1%EB%A1%9D
 * @param params
 * @param config
 */

export interface UsedFavoriteRouteInfo {
  routeId: string;
  routeDescription?: string;
  departName: string;
  departXPos: number; // SK Bessel 좌표, 7자리
  departYPos: number; // SK Bessel 좌표, 7자리
  destName: string;
  destXPos: number; // SK Bessel 좌표, 7자리
  destYPos: number; // SK Bessel 좌표, 7자리
  destPoiId?: string;
  destNavSeq?: string;
  destPkey?: string;
  oldRouteId?: string
}


function registerUsedFavoriteRoute(params: UsedFavoriteRouteInfo[], config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  const requestTime = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss')
  return frontmanAxios.post<EscApiBody>('/esc/esc-channel/poi/favorite/registusedfavoriteroute', {
    usedFavoriteRoutes: params,
    header: {
      reqTime: requestTime
    }
  }, {
    ...restConfig,
    headers: {
      UserKey: '$userKey',
      accept: 'application/json',
      ...configHeaders
    }
  })
}

export { registerUsedFavoriteRoute }
