let fixScrollStack = 0

export function fixScroll(className: string) {
  fixScrollStack++
  if (fixScrollStack === 1) {
    window.document.body.classList.add(className)
  }
}

export function unfixScroll(className: string) {
  fixScrollStack--
  if (fixScrollStack === 0) {
    window.document.body.classList.remove(className)
  }
}
