import { useCallback, useContext, useEffect } from 'react'
import { DialogManager } from './DialogManager'
import { DialogManagerContext } from './Provider'
import { HistoryAction, HistoryBlockHook, HistoryChangeListener, HistoryLocation } from './types'
import { serializeLocation } from './utils'

type RouterType = 'nextjs' | 'react-router'

let routerType: RouterType

function useDialogManager<Action extends HistoryAction, State>() {
  return useContext(DialogManagerContext) as DialogManager<Action, State>
}

/**
 * history 구현체의 history변경 이벤트 리스너 대신 사용하는 훅.
 * 다이얼로그 열기/닫기 발생하는 네비게이션 이벤트 전파를 거르기 위함.
 */
function useListenHistory<Action extends HistoryAction, State>() {
  useCheckInited()

  const dialogManager = useDialogManager<Action, State>()
  return useCallback((listener: HistoryChangeListener<Action, State>) => {
    return dialogManager.listenHistory(listener)
  }, [dialogManager])
}

/**
 * history 구현체의 history되돌리기 기능과 DialogManager를 연동해주는 훅.
 * History popstate이벤트 발생시, DialogManager가 가장 먼저 이벤트를 받아 다이얼로그를 닫고 전파를 막기위함.
 */
function useBlockHistory<Action extends HistoryAction, State>() {
  useCheckInited()

  const dialogManager = useDialogManager<Action, State>()

  // location옵션 사용하면 안됨. 하위 호환을 위해 남겨둠.
  // location옵션의 의도는 한 페이지내에서 useBlockHistory을 여러개 사용할 경우를 고려해 내부에서 필터링을 하려던 것인데,
  // useBlockHistory 사용해보니 사용하는 쪽에서 onBlocked가 예상대로 호출되지 않을때 디버깅이 매우 어려워짐.
  // 사용하는 쪽에서 onBlock을 통해 필터링하는게 나음.
  return useCallback(({
    location,
    onBlock,
    onBlocked
  }: {
    location?: HistoryLocation<State>;
    onBlock: HistoryBlockHook<Action, State>;
    onBlocked?: () => void;
  }) => {
    /**
     * history.block 걸고 history back(history.go(-1))을 일으키면 popstate 이벤트가 2번 발생한다.
     * history.go(-1) 한번, 되돌아가기 위해 history.go(1) 한번.
     *
     * react-router는,
     * 첫번째 popstate에서 history내부 location, action 을 업데이트하지 않고 history.go 호출한다.
     * 두번째 popstate에서 업데이트 하지않은 location, action 값으로 history listener들에게 노티한다.
     *
     * history.block hook은 첫번째 popstate에서 호출하는데 이때 hook에서 history.push/replace 호출하면
     * popstate > push/replace > popstate 순서로 진행되기 때문에,
     * history.push/replace 호출로 기대한 페이지가 두번째 popstate로 인해 나타나지 않고
     * 두번째 popstate에서 history listener들에게 노티할때 전달한 location에 해당하는 페이지가 나타난다.
     * 따라서 블로킹후에 history.push/replace 호출을 원한다면 두번째 popstate에서 호출해야한다.
     *
     * blocking을 결정하는 콜백과, blocked를 알리는 콜백을 따로 두어,
     * 첫번째 popstate에서 체크해두고 두번째 popstate에서 blocked를 알린다.
     */
    const basePath = location ? serializeLocation(location) : null
    let blocked = false

    const unblock = dialogManager.blockHistory((location, action) => {
      const result = onBlock(location, action)
      if (result) {
        if (action !== 'POP') {
          onBlocked?.()
        } else {
          blocked = true
        }
      }
      return result
    })

    const unregister = dialogManager.listenHistory((location) => {
      if (blocked && (basePath == null || (serializeLocation(location) === basePath))) {
        onBlocked?.()
        blocked = false
      }
    })

    return () => {
      unblock()
      unregister()
    }
  }, [dialogManager])
}

function useInit(targetRouter: RouterType) {
  useEffect(() => {
    routerType = targetRouter
  }, [targetRouter])
}

function useCheckInited() {
  useEffect(() => {
    if (!routerType) {
      throw new Error(`"@tmap-web-lib/dialog-manager/${routerType}"패키지 "Provider"를 사용해주세요.`)
    }
  }, [])
}

export {
  useInit,
  useCheckInited,
  useDialogManager,
  useListenHistory,
  useBlockHistory,
}
