import React, { ButtonHTMLAttributes } from 'react'
import { Button } from './styled-components'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>{
  activated?: boolean
}

function DevToolButton(props: Props) {
  const { type = 'button', activated = false, ...attrs } = props

  return (
    <Button
      type={type}
      activated={activated}
      {...attrs}
    />
  )
}

export { DevToolButton }
