import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { CarMaintenanceDataType, DeepPartial } from './types'


/**
 * 내 차 관리 정보 등록
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/451674130
 * @param carProfileId
 * @param params
 * @param config
 */

function registerCarMaintenance(carProfileId: string, params: DeepPartial<Omit<DeepPartial<CarMaintenanceDataType>, 'createdAt' | 'updatedAt'>>, config: AxiosRequestConfig = {}) {
  return frontmanAxios.post<void>(`/driving-score/mycar/users/$userKey/cars/${carProfileId}/maintenance`, params, config)
}

export { registerCarMaintenance }
