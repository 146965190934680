import { AxiosError, AxiosInstance } from 'axios'
import isUndefined from 'lodash/isUndefined'
import formatDateTime from 'date-fns/format'
import { CoreConfig, getKSTNow, RequestConfig } from '../common'
import { AxiosConfig, frontmanAxios, initAxios } from './axios'

type FrontmanRequestHeaders = {
  Using: string;
  'App-Code'?: string;
  'App-Version'?: string;
  'Os-Type'?: string;
  'Os-Version'?: string;
  'Device-Id'?: string;
  'Model-No'?: string;
  'Build-No'?: string;
  Carrier?: string;
  'Body-Replace'?: boolean;
}

type ClientConfig = Omit<CoreConfig, 'axiosList' | 'onAccessKeyExpired' | 'onAccessKeyChanged'> & AxiosConfig & {
  headers?: FrontmanRequestHeaders;
  onAccessKeyChanged: (newAccessKey: string) => Promise<any> | void;
  onAccessKeyExpired?: (error: AxiosError, axios: AxiosInstance) => Promise<void> | void;
  onError?: (error: AxiosError, axios: AxiosInstance) => Promise<any> | void;
}

/**
 * - onAccessKeyExpired : status code 401 발생했을때 호출.
 *   인증 토큰 갱신후 리트라이 같은 처리가 필요할때 사용.
 *   리턴값이 없으면 원본 에러로 Promise.reject 진행. rejected promise를 리턴하지 않으면 에러를 무시하고 fulfilled 흐름으로 진행.
 * - onError : 응답 에러시 호출. (4xx, 5xx 및 네트워크 장애)
 *   onAccessKeyExpired 를 사용하지 않으면 모든 응답 에러 발생시 호출하고 함께 사용하면 status code 401 이 아닌 응답 에러만 호출.
 *   리턴값이 없으면 원본 에러로 Promise.reject 진행. rejected promise를 리턴하지 않으면 에러를 무시하고 fulfilled 흐름으로 진행.
 */
function initFrontmanApiClient({
  onAccessKeyChanged,
  onAccessKeyExpired,
  onRequest = async (config) => config,
  onResponse,
  onError,
  ...envConfig
}: ClientConfig) {
  initAxios(envConfig)

  frontmanAxios.interceptors.request.use((config) => {
    config.headers = config.headers || {}
    config.headers['Req-Time'] = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss')
    return onRequest(config as RequestConfig, frontmanAxios)
  })

  frontmanAxios.interceptors.response.use(async (response) => {
    const { headers } = response
    const newAccessKey = headers['new-accesskey']
    if (newAccessKey) {
      await onAccessKeyChanged(newAccessKey)
    }
    return onResponse ? onResponse(response, frontmanAxios) : response
  }, async (error) => {
    if (onAccessKeyExpired && error.response?.status === 401) {
      const result = await onAccessKeyExpired(error, frontmanAxios)
      return isUndefined(result) ? Promise.reject(error) : result
    }

    if (onError) {
      const result = await onError(error, frontmanAxios);
      return isUndefined(result) ? Promise.reject(error) : result
    }

    return Promise.reject(error)
  })
}

export { initFrontmanApiClient as init }
