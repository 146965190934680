import React, { forwardRef, HTMLAttributes } from 'react'
import { Text } from './styled-components'

interface Props extends HTMLAttributes<HTMLSpanElement> {}

const DevToolText = forwardRef<HTMLSpanElement, Props>(function DevToolText(props, ref) {
  return <Text ref={ref} {...props}/>
})

export { DevToolText }
