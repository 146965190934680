import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../../axios'
import {AdsServingResBody, GetAdsServingRequestParams, RequireAtLeastOne} from './types'

/**
 * 광고 조회 (광고개발)
 * @see https://tmobi.atlassian.net/wiki/spaces/ADP/pages/317688178/Ads+Serving+API
 * @param params
 * @param config
 */

function getAdsServing(params: RequireAtLeastOne<GetAdsServingRequestParams, "inventoryCode" | "inventoryCodes">, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.get<AdsServingResBody>('/proxy/ads/serving/ads', {
    ...restConfig,
    headers: {
      ...configHeaders,
      userkey: '$userKey',
    },
    params: {
      ...params
    },
  })
}

export { getAdsServing }
