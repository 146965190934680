import defer from 'lodash/defer'
import { getRootScrollingElement } from './get-root-scrolling-element'

interface Options {
  sync?: boolean;
}

/**
 * scrollTop 프로퍼티 접근으로 인해 프레임드롭이 발생할때 async옵션을 사용해 scrollTop 프로퍼티 접근을 다음 틱으로 미룬다.
 */
export function setRootScrollTop(y: number, {
  sync = false,
}: Options = {}) {
  const setScrollTop = () => {
    getRootScrollingElement().scrollTop = y
  }

  sync ? setScrollTop() : defer(setScrollTop)
}
