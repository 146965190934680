import React, { ComponentType, createContext, PropsWithChildren, useMemo, useState } from 'react'
import { DialogContainer } from './DialogContainer'
import { DialogManager } from './DialogManager'
import { DialogItem, DialogManagerOptions } from './types'

interface Props extends PropsWithChildren<Pick<DialogManagerOptions, 'onNeedHistoryBack' | 'onNeedHistoryPush'>> {
  dialogPortalId?: string;
  dialogListWrapper?: ComponentType;
}

const DialogManagerContext = createContext(null as unknown as DialogManager<any>)

function Provider(props: Props) {
  const { dialogPortalId, dialogListWrapper, onNeedHistoryBack, onNeedHistoryPush, children } = props
  const [dialogList, dispatchDialogList] = useState<DialogItem[]>([])
  const dialogManager = useMemo(() => new DialogManager({
    onDialogListChanged(dialogList: DialogItem[]) {
      dispatchDialogList(dialogList)
    },
    onNeedHistoryBack,
    onNeedHistoryPush,
  }), [onNeedHistoryBack, onNeedHistoryPush])

  return (
    <DialogManagerContext.Provider value={dialogManager}>
      {children}
      <DialogContainer
        dialogPortalId={dialogPortalId}
        dialogList={dialogList}
        dialogListWrapper={dialogListWrapper}
      />
    </DialogManagerContext.Provider>
  )
}

export {
  Provider,
  DialogManagerContext,
}
