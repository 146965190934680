import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { UserExpectedRanks, UserExpectedRanksParams } from "./types"

/**
 * 사용자 예상 랭킹 조회(점수별)
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/501448705
 * @param params
 * @param config
 */
function getUserExpectedRanks({ score }: UserExpectedRanksParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<UserExpectedRanks>('/driving-score/report/ranks/$userKey/expected', {
    ...config,
    params: {
      score
    }
  })
}

export { getUserExpectedRanks }
