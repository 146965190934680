import React, { HTMLAttributes } from 'react'
import { Description } from './styled-components'

interface Props extends HTMLAttributes<HTMLParagraphElement>{}

function DevToolDescription(props: Props) {
  return (
    <Description {...props}/>
  )
}

export { DevToolDescription }
