import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { MonthlyReportsResponseData } from "./types";

/**
 * 월별 리포트 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/550306473
 * @param params
 * @param config
 * 데이터가 없는 경우 빈배열 리턴("reports": [])
 */

export interface MonthlyReportsRequestParams {
  lastMonth?: number // Default 12(개월)
}

function getMonthlyReports(params?: MonthlyReportsRequestParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<MonthlyReportsResponseData>(`/ubds/trip-report/v1/monthly-reports/$userKey?lastMonth=${params?.lastMonth}`, config)
}

export { getMonthlyReports }
