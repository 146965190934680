import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { TripsDetailParams } from './types'

/**
 * 주행 이력 삭제
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/550306403
 * @param tripInitSessionId
 * @param config
 */

function deleteTrips({ tripInitSessionId }: TripsDetailParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.delete<void>(`/ubds/trip/v1/trips/${tripInitSessionId}`, {
    headers: {
      UserKey: '$userKey',
      ...configHeaders,
    },
    ...restConfig,
  })
}

export { deleteTrips }
