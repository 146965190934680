import React, { ForwardedRef, forwardRef, LiHTMLAttributes, ReactElement, ReactNode, RefObject } from 'react'
import { DevToolCopyButton } from './DevToolCopyButton'
import { DevToolItemContent } from './DevToolItemContent'
import { DevToolItemHeader } from './DevToolItemHeader'
import { Row } from './styled-components'

interface Props<E extends HTMLElement> extends LiHTMLAttributes<HTMLLIElement>{
  label: string;
  copyContentRef?: RefObject<E>;
  actionButtons?: ReactElement | null;
  children: ReactNode;
}

const DevToolItem = forwardRef(function DevToolItem<E extends HTMLElement, T>(props: Props<E>, ref: ForwardedRef<HTMLLIElement>) {
  const {
    label,
    actionButtons,
    copyContentRef,
    children,
    ...attrs
  } = props

  return (
    <Row {...attrs} ref={ref}>
      <DevToolItemHeader
        actionButtons={<>
          {actionButtons}
          {copyContentRef && <DevToolCopyButton contentRef={copyContentRef}/>}
        </>}
      >{label}</DevToolItemHeader>
      <DevToolItemContent>{children}</DevToolItemContent>
    </Row>
  )
})

export { DevToolItem }
