import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { TripsDetailParams, TripsDetailResponse } from './types'

/**
 * 주행 이력 상세 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/550306344
 * @param tripInitSessionId
 * @param config
 */

function getTripsDetail({ tripInitSessionId }: TripsDetailParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.get<TripsDetailResponse>(`/ubds/trip/v1/trips/${tripInitSessionId}`, {
    headers: {
      UserKey: '$userKey',
      ...configHeaders,
    },
    ...restConfig,
  })
}

export { getTripsDetail }
