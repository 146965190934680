import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react'
import { DevToolDefaultPanel, DevToolTriggerButton } from './base'
import { PanelAnchor, Root, TriggerButtonWrap } from './base/styled-components'
import { Config, ConfigProvider } from './providers'

type Props = PropsWithChildren<{
  show?: boolean;
  trigger?: ReactElement;
  config?: Config;
}>

export function DevTools(props: Props) {
  const {
    show,
    trigger,
    config,
    children,
  } = props

  const [showPanel, setShowPanel] = useState(show)

  const triggerButton = useMemo(() =>
    <TriggerButtonWrap>
      {trigger
        ? trigger
        : <DevToolTriggerButton onClick={() => setShowPanel(show => !show)} />
      }
    </TriggerButtonWrap>, [])

  useEffect(() => {
    setShowPanel(show)
  }, [show])

  return (
    <ConfigProvider config={config}>
      <Root
        baseFontSize={config?.baseFontSize}
        baseTextColor={config?.baseTextColor}
        baseBackgroundColor={config?.baseBackgroundColor}
      >
        {config?.customized
          ? children
          : (
            <PanelAnchor>
              {showPanel
                ? <DevToolDefaultPanel>{children}</DevToolDefaultPanel>
                : null
              }
              {triggerButton}
            </PanelAnchor>
          )
        }
      </Root>
    </ConfigProvider>
  )
}
