import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { CarMaintenanceDataType } from './types'


/**
 * 내 차 관리 정보 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/447678583
 * @param carProfileId
 * @param config
 */



function getCarMaintenance(carProfileId: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<Required<CarMaintenanceDataType>>(`/driving-score/mycar/users/$userKey/cars/${carProfileId}/maintenance`, config)
}

export { getCarMaintenance }
