import { UserMessageResetReqType } from './types'
import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'

/**
 * 사용자 메시지 표출 상태 초기화
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/570593606
 * @param params
 * @param config
 */

function updateUserMessageReset(params: UserMessageResetReqType, config: AxiosRequestConfig = {}) {
  return frontmanAxios.post<void>('/driving-score/msg/message/$userKey/display-states/reset', params, config)
}

export {updateUserMessageReset}
