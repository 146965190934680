import { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';
import {
  CarOptions,
  CarProfile,
  CarProfileWonbu,
  FuelType,
  GearBoxType,
  InsurerCode,
  VehicleModel,
  YesNo,
} from '../../../common';
import { OuterCarInfo } from '../../../esc-proxy';
import { frontmanAxios } from '../../axios';

// legacy type, 추후에 운전점수 및 차량프로필 타입 변경 후 삭제할 것
export interface UpdateCarProfileParam {
  carProfileId: string;
  mainYn: YesNo;
  model?: VehicleModel | null | '';
  fuel?: FuelType | null | '';
  hipassYn?: YesNo | null;
  carNumber?: string | null;
  makerName?: string | null;
  carName?: string | null;
  ownerName?: string | null;
  gearBox?: GearBoxType | null | '';
  seater?: number | null;
  insurer?: InsurerCode | null | '';
  insRegDate?: string | null;
  tscoreYn?: YesNo;
  options?: Partial<CarOptions> | null | {};
  tags?: string[];
  extCarInfo?: Partial<OuterCarInfo | null> | {};
  insurance?: Partial<{
    insurer: string | null;
    insRegDate: string | null;
  } | null>;
  carWonbu?: Partial<CarProfileWonbu> | null | {};
  jointInfo?: Partial<{
    jointOwnerName: string;
    findOwnerName: string;
  }> | null;
}

export interface UpdateCarProfileResData {
  code: string;
  message: string;
  errorDetails: string | null;
  exceptionName: string;
  reasonCode: string;
}

export type UpdateCarProfileRequestParam = Omit<
  Partial<CarProfile>,
  'seq' | 'seriesList'
> & {
  carProfileId: string;
  mainYn: YesNo;
};

/**
 * 차량 프로필 수정
 * @see https://tmobi.atlassian.net/l/cp/0yrBsaB0
 * @param params
 * @param config
 */

function updateCarProfile(
  params: UpdateCarProfileRequestParam | UpdateCarProfileParam,
  config: AxiosRequestConfig = {}
) {
  return frontmanAxios.put<UpdateCarProfileResData | null>(
    `/flerken/api/v3/car-info/$userKey`,
    omit(params, ['seq', 'seriesList']),
    config
  );
}

export { updateCarProfile };
