import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { UserRanks } from "./types";

/**
 * 사용자 랭킹 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/418645442
 * @param config
 */
function getUserRanks(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<UserRanks>('/driving-score/report/ranks/$userKey', config)
}

export { getUserRanks }
