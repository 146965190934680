import formatDateTime from 'date-fns/format';
import { EscApiBody, getKSTNow } from '../../../common';
import { BaseCarInfo } from '../../../esc-proxy';
import { frontmanAxios } from '../../axios';
import { omit } from 'lodash';
import { AxiosRequestConfig } from 'axios'

export interface RegisterCarWonbuParam extends BaseCarInfo {
  carProfileId: string;
}

/**
 * 차량 원부 정보 등록
 * @see https://tmobi.atlassian.net/l/cp/7BD48Xhk
 * @param param
 * @param config
 */
function registerOuterCarWonbu(param: RegisterCarWonbuParam, config: AxiosRequestConfig = {}) {
  const { carProfileId } = param;
  const { headers: configHeaders, ...restConfig } = config;

  const carInfo = omit(param, ['carProfileId']);
  const requestTime = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss');
  return frontmanAxios.post<EscApiBody>(
    '/esc/esc-channel/profile/outercarinfo/find/carwonbu/async',
    {
      header: {
        reqTime: requestTime,
      },
      carInfo,
      carProfileId,
    },
    {
      ...restConfig,
      headers: {
        Accept: 'application/json',
        ...configHeaders
      },
    }
  );
}

export { registerOuterCarWonbu };
