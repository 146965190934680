import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { UserMessageResType } from './types'

/**
 * 사용자 개인화 메세지 (신규)
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/564789772
 * @param config
 */

function getUserMessage(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<UserMessageResType>('/driving-score/msg/message/$userKey', config)
}

export { getUserMessage }
