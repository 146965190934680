import { UpdateEventJoinStatusRequestType } from './types'
import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import qs from 'qs'

/**
 * 이벤트 참여/취소
 * @see https://tmobi.atlassian.net/wiki/spaces/FYJNOOBUUR/pages/31951228/API+-+01.#id-%EC%9D%B4%EB%B2%A4%ED%8A%B8API-01.%EC%9D%B4%EB%B2%A4%ED%8A%B8%EC%B0%B8%EC%97%AC-03.AccessKey%ED%8C%8C%EB%9D%BC%EB%AF%B8%ED%84%B0%EA%B0%80%EC%9E%88%EB%8A%94%EA%B2%BD%EC%9A%B0
 * @see frontman 연동 가이드 : https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/415367258/Event+API#1.--%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%B0%B8%EC%97%AC-API
 */

function updateEventJoinStatus(params: UpdateEventJoinStatusRequestType, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.post<void>('/event/event/command_ak_to_uk', qs.stringify(params), {
    ...restConfig,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...configHeaders
    }
  })
}

export { updateEventJoinStatus }
