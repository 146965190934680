import React, { forwardRef, InputHTMLAttributes } from 'react'
import { Input } from './styled-components'

interface Props extends InputHTMLAttributes<HTMLInputElement> {}

const DevToolInputText = forwardRef<HTMLInputElement, Props>(function DevToolInputText(props, ref) {
  const { type = 'text', ...restProps } = props
  return <Input {...props} ref={ref} type={type} />
})

export { DevToolInputText }
