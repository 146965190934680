import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { BenefitCategories } from './types'

/**
 * 보험 카테고리 목록 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/294657851/2023+API+Specification#%ED%98%9C%ED%83%9D-%EC%B9%B4%ED%85%8C%EA%B3%A0%EB%A6%AC-%EB%AA%A9%EB%A1%9D-%EC%A1%B0%ED%9A%8C
 * @param config
 */

function getBenefitCategories(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<BenefitCategories[]>('/driving-score/bnf/benefit-categories', config)
}

export { getBenefitCategories }
