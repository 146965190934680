import { AxiosRequestConfig } from "axios";
import { frontmanAxios } from "../../axios";
import { CharanchaPopularCarResData } from "./types";


/**
 * 인기매물 리스트 조회
 * @see https://dev-tmap-open-api.charancha.com/docs/car.html#_%EC%9D%B8%EA%B8%B0%EB%A7%A4%EB%AC%BC_%EB%A6%AC%EC%8A%A4%ED%8A%B8_%EC%A1%B0%ED%9A%8C
 * @param limit // 최대 요청 건수
 * @param config
 */

function getCharanchaPopularCarList(limit?: number, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<CharanchaPopularCarResData>(`/charancha/v1/cars/-/popular?limit=${limit ?? 20}`, {...config})
}

export { getCharanchaPopularCarList }
