import {AxiosRequestConfig} from "axios";
import {frontmanAxios} from "../../axios";
import {LatestViolationData} from "./types";

/**
 * 최근 주행 감정 요인 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/587368594
 * @param config
 */

function getLatestViolations(config: AxiosRequestConfig = {}) {
    return frontmanAxios.get<LatestViolationData>('/ubds/trip-report/v1/latest-violations/$userKey', config)
}

export { getLatestViolations }
