import Axios, { AxiosRequestConfig } from 'axios'

export interface AxiosConfig extends AxiosRequestConfig {
  hostUrl?: string;
  basePath?: string;
}

function initAxios({
  hostUrl = '',
  basePath = '',
  ...restConfig
}: Omit<AxiosConfig, 'url' | 'baseURL'>) {
  Object.assign(frontmanAxios.defaults, {
    baseURL: `${hostUrl}${basePath}`,
    timeout: 10000,
    ...restConfig,
  })
}

const frontmanAxios = Axios.create()

export { frontmanAxios, initAxios }
