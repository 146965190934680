import { AxiosRequestConfig } from "axios"
import { frontmanAxios } from "../../axios"
import { GetTermsAgreementResData } from "./types"

/**
 * 사용자 정보 삭제 (약관 철회)
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPAUTH/pages/584614741/12.
 * @param termsType
 * @param config
 */

export function deleteTermsAgreement(termsType: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.delete<Pick<GetTermsAgreementResData, 'resultCode'>>(`/heimdall/heimdall-rmi/api/v1/users/$userKey?termsType=${termsType}`, config)
}