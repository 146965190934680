import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { YesNo } from "../../../common";
import { UbdsApiStatusResponse, TotalSafeDrivingInfo } from "./types";

/**
 * 종합 안전운전 정보 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/722501633
 * @param params
 * @param config
 */

export interface TotalSafeDrivingRequestParams {
  latestTripIdYn?: YesNo
  estimatedScoreYn?: YesNo
}

export interface TotalSafeDrivingInfoResData extends UbdsApiStatusResponse {
  data: TotalSafeDrivingInfo
}

function getTotalSafeDriving(params: TotalSafeDrivingRequestParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.post<TotalSafeDrivingInfoResData>('/ubds/trip-report/v1/channel/ubds-rmi/RestApi/SafeDrivingService/findTotalSafeDrivingInfo', {
    latestTripIdYn: params.latestTripIdYn,
    estimatedScoreYn: params.estimatedScoreYn,
    userKey: '$userKey'
  }, {
    ...restConfig,
    headers: {
      'Body-Replace': true,
      ...configHeaders
    }
  })
}

export { getTotalSafeDriving }
