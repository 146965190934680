import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { GetInsuranceDiscountsRequestParams, InsuranceDiscounts } from './types'

/**
 * 사용자 보험 할인 정보 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/415367202/API
 * @param benefitId 조회 대상 혜택 ID
 * @param config
 * query string으로 userKey를 조회하는 것이 특징
 */

function getInsuranceDiscounts({ benefitId }: GetInsuranceDiscountsRequestParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<InsuranceDiscounts>(`/driving-score/bnf/insurance-discounts/${benefitId}?userKey=$userKey`, config)
}

export { getInsuranceDiscounts }
