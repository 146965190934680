import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import {
  GetScoreContentsListRequestParams,
  ScoreContentsList
} from './types'

/**
 * 운전점수 콘텐츠(Tip) 목록 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/FYJNOOBUUR/pages/289095034/M+ADMIN+-+-
 * @param pageNo 페이징 번호(default: 1)
 * @param pageSize 페이지 사이즈(default: 10, 최대: 100)
 * @param scoreContentsStatus 0 or null: 전체 콘텐츠, 1: 진행중 콘텐츠, 2: 종료된 콘텐츠
 * @param config
 */
function getScoreContentsList({ pageNo, pageSize, scoreContentsStatus = '1' }: GetScoreContentsListRequestParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<ScoreContentsList>('/square-admin/user/api/score-contents', {
    ...config,
    params: {
      pageNo,
      pageSize,
      scoreContentsStatus
    }
  })
}
export { getScoreContentsList }
