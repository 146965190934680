import { History } from 'history'
import React, { ComponentType, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { DIALOG_MANAGER_HISTORY_MARKER_KEY } from '../DialogManager'
import { useInit } from '../hooks'
import { Provider } from '../Provider'
import { createStateStore } from '../utils'
import { useDialogManager } from './hooks'

type Props = PropsWithChildren<{
  history: History;
  dialogPortalId?: string;
  dialogListWrapper?: ComponentType;
}>

const { getStateValue, setStateValue } = createStateStore(() => ({
  dialogHistoryPopped: false,
}))

/**
 * react-router 네비게이션 동작.
 * History.listen, history.block을 사용해 페이지 네비게이션(Router.push/replace, popstate이벤트)이벤트를 받을수 있다.
 * 이벤트 발생시 push/replace/pop 종류를 알려준다.
 * History.listen은 단방향으로 흐름 제어에 끼어들수 없다.
 * History.block의 리턴값으로 네비게이션을 진행하거나 막을수 있다.
 * History.block에서 네비게이션이 막히면 History.listen을 호출하지 않는다.
 *
 * react-router와 다이얼로그 연동.
 * 다이얼로그를 열때 현재 url과 동일하게 history.push 호출해서 현재 페이지를 그대로 유지한다.
 * - 이때 발생하는 네비게이션 이벤트는 DialogManager.onChangeHistory 호출하지 않도록 막는다.
 * 다이얼로그를 닫을때는 popstate이벤트를 발생시켜 History.block에서 페이지 전환을 차단한다.
 * - 닫아야 할 다이얼로그가 있으면 return false하여 DialogManager.onChangeHistory 호출하지 않음.
 */
function ProviderForReactRouter(props: Props) {
  const { history, dialogPortalId, dialogListWrapper, children } = props
  const id = useMemo(() => uuid(), [])
  const handleNeedHistoryPush = useCallback(dialogId => {
    const location = {
      pathname: history.location.pathname,
      search: history.location.search,
      hash: history.location.hash,
      state: { [DIALOG_MANAGER_HISTORY_MARKER_KEY]: dialogId },
    }
    history.push(location)
  }, [history])
  const handleNeedHistoryBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Provider
      dialogPortalId={dialogPortalId}
      dialogListWrapper={dialogListWrapper}
      onNeedHistoryPush={handleNeedHistoryPush}
      onNeedHistoryBack={handleNeedHistoryBack}
    >
      <Init id={id} history={history}/>
      {children}
    </Provider>
  )
}

function Init({ id, history }: { id: string; history: History }) {
  const dialogManager = useDialogManager()

  useEffect(() => {
    const unblock = history.block((location, action) => {
      const result = dialogManager.onBlockHistory(location, action)
      if (result === 'DIALOG_HISTORY_POPPED') {
        setStateValue(id, 'dialogHistoryPopped', true)
        // return false 하면 네비게이션 blocking이 진행되어 history.go 호출로 popstate이벤트 발생하기 떄문에 통과시킴.
        // history.listen에서 네비게이션 이벤트 전파를 막기 위해 내부 플래그(dialogHistoryPoppeds) 사용.
        return
      }
      return result === 'BLOCKED' ? false : undefined
    })
    const unregister = history.listen((location, action) => {
      if (getStateValue(id,'dialogHistoryPopped')) {
        setStateValue(id, 'dialogHistoryPopped', false)
        return
      }
      dialogManager.onChangeHistory(location, action)
    })
    return () => {
      unblock()
      unregister()
    }
  }, [history, dialogManager])

  useInit('react-router')

  return null
}

export { ProviderForReactRouter as Provider }
