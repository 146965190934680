import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import {
  GetScoreContentsDetailRequestParams,
  ScoreContentsDetail
} from './types'

/**
 * 운전점수 콘텐츠(Tip) 상세 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/FYJNOOBUUR/pages/289095034/M+ADMIN+-+-
 * @param seq 콘텐츠 일련 번호
 * @param config
 */
function getScoreContentsDetail({ seq }: GetScoreContentsDetailRequestParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<ScoreContentsDetail>(`/square-admin/user/api/score-contents/detail/${seq}`, config)
}
export { getScoreContentsDetail }
