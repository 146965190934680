import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { EventBannerResponseType } from './types'

/**
 * 진행중인 이벤트 배너 리스트 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPEVENT/pages/25790272/Event+-+02.
 * @see frontman 연동 가이드 : https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/415367258/Event+API#2.--%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EB%B0%B0%EB%84%88-%EB%A6%AC%EC%8A%A4%ED%8A%B8-%EC%A1%B0%ED%9A%8C-API-(AK-%EB%B6%88%ED%95%84%EC%9A%94--%3E%ED%94%84%EB%A1%9D%EC%8B%9C%EB%AA%A8%EB%93%9C)
 * @param config
 */


function getEventBannerList(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<EventBannerResponseType>('/proxy/event/api/event/banner', config)
}

export { getEventBannerList }
