import React, { forwardRef, HTMLAttributes } from 'react'
import { Pre } from './styled-components'

interface Props extends HTMLAttributes<HTMLPreElement>{}

const DevToolPreFormattedText = forwardRef<HTMLPreElement, Props>(function DevToolPreFormattedText(props, ref) {
  return (
    <Pre ref={ref} {...props}/>
  )
})

export { DevToolPreFormattedText }
