import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { ModelAverageScores } from "./types/user-report";

/**
 * 사용자 랭킹 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUCG/pages/195559648/092.+API+Specification
 * @param config
 */

function getModelAverageScores(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<ModelAverageScores>('/driving-score/report/model-average-scores?byUserKey=$userKey', config)
}

export { getModelAverageScores }