import { AxiosRequestConfig } from 'axios';
import { frontmanAxios } from '../../axios';
import { YesNo } from '../../../common'

/**
 * 차량 리콜 정보
 * @see https://tmobi.atlassian.net/wiki/spaces/FLERKEN/pages/408491931/25.+API
 * @param carProfileId
 * @param config
 */

export interface RecallInfos {
  recallId: string;
  recallTitle: string;
  recallUrl: string;
  recallYn: YesNo;
  recallFromDate: string;
  recallToDate: string | null;
  recallSource: string;
  insDatetime: string;
  updDatetime: string;
}

export interface RecallInfoResponseData {
  recallInfos: RecallInfos[] | []
}

function getRecallInfo(carProfileId: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<RecallInfoResponseData>(
    `/flerken/api/v1/recall-info/${carProfileId}`,
    config
  );
}

export { getRecallInfo };
