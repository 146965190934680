import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import {TripsListReqParams, TripsListResData} from "./types";

/**
 * 주행 이력 목록 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/550306374
 * @param nextToken
 * @param lastMonth
 * @param createdAt
 * @param includeOriginCoord
 * @param config
 */

function getTrips({ nextToken, lastMonth, createdAt, includeOriginCoord }: TripsListReqParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  let params
  // lastMonth 또는 createdAt 둘 중 하나만 보낼 수 있고 둘 중 하나 필수로 보내야 함.
  if (lastMonth) {
    params = `?lastMonth=${lastMonth}`
  } else if (createdAt) {
    params = `?createdAt=${createdAt}`
  }

  if (nextToken) {
    params = `${params}&nextToken=${nextToken}`
  }

  if (includeOriginCoord) {
    params = `${params}&includeOriginCoord=true`
  }

  return frontmanAxios.get<TripsListResData>(`/ubds/trip/v1/trips${params}`, {
    headers: {
      UserKey: '$userKey',
      ...configHeaders,
    },
    ...restConfig,
  })
}

export { getTrips }
