import { AxiosRequestConfig } from 'axios';
import { CarProfile } from '../../../common';
import { frontmanAxios } from '../../axios';

/**
 * 차량 프로필 단건 조회
 * @see https://tmobi.atlassian.net/l/cp/v4RKknW0
 * @param carProfileId
 * @param config
 */
function getCarProfile(carProfileId: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<CarProfile>(
    `/flerken/api/v3/car-info/carinfos/${carProfileId}`,
    config
  );
}

export { getCarProfile };
