import { frontmanAxios } from "../../axios";
import { AxiosRequestConfig } from "axios";
import { UbPayUnpaidTollsEncData } from "../../../carlife";

/**
 * 미납 통행료 내역/ 유무 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/712213041/Ubpay+extra+pay
 * @param params
 * @param config
 */

function getUbpayUnpaidTollData(params: UbPayUnpaidTollsEncData, config: AxiosRequestConfig = {}) {
  return frontmanAxios.post<string>('/ubpay/expayTmap/API', {
    ver: params.var,
    c_id: params.c_id,
    encData: params.encData
  }, {
    ...config,
    timeout: 60000,
  })
}

export { getUbpayUnpaidTollData }