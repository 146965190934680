import { RedddotListData } from '../../../../common';
import { frontmanAxios } from '../../../axios';
import { AxiosRequestConfig } from 'axios'

/**
 * 해당 유저의 reddot list 호출
 * @see https://tmobi.atlassian.net/l/cp/DaNw21n0
 * @param itemGroup
 * @param config
 */
function getUserReddotList(itemGroup?: string, config: AxiosRequestConfig = {}) {
  let groupName = '';
  if (itemGroup) {
    groupName = `?itemGroup=${itemGroup}`;
  }
  return frontmanAxios.get<RedddotListData>(`/friday/api/v1/user/$userKey/badges${groupName}`, config);
}

export { getUserReddotList };
