import { AxiosRequestConfig } from 'axios';
import { UserInfoData } from '../../../../common';
import { frontmanAxios } from '../../../axios';

/**
 * 유저 민감 정보를 제외한 유저 이름 및 본인 인증 여부 확인
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPAUTH/pages/30146758/10.+UserKey?atlOrigin=eyJpIjoiYzZkM2M3MjFkM2M4NGMzZGIxYzcxZDNjNDM4NmMxY2EiLCJwIjoiYyJ9
 * @param config
 */
function getUserInfo(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<UserInfoData>(
    `/heimdall/heimdall-rmi/api/user/$userKey`,
    config
  );
}

export { getUserInfo };
