import { UbPayUnpaidTollsPaymentRequest, UbPayUnpaidTollsQueryRequest } from "../../../carlife";
import { AxiosRequestConfig } from "axios";
import { frontmanAxios } from "../../axios";
import qs from "qs";

/**
 * 미납통행료 텍스트 암호화
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPEVENT/pages/658770532/SquareAdmin+-+-#01.-%EB%AF%B8%EB%82%A9%ED%86%B5%ED%96%89%EB%A3%8C-%ED%85%8D%EC%8A%A4%ED%8A%B8-%EC%95%94%ED%98%B8%ED%99%94
 * @param params
 * @param config
 */

export interface UbpayEncryptDataRes {
  result: string
}

function getUbpayEncryptData(params: UbPayUnpaidTollsQueryRequest | UbPayUnpaidTollsPaymentRequest, config: AxiosRequestConfig = {}) {
  return frontmanAxios.post<UbpayEncryptDataRes>('/square-admin/user/api/partners/expay/encrypt',
    {text: JSON.stringify(params)}, config)
}

export { getUbpayEncryptData }