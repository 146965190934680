import { AxiosRequestConfig } from 'axios'
import { FavoritePoiListResData, YesNo } from '../../../common'
import { frontmanAxios } from '../../axios'

/**
 * - myFavoriteYn : 집/회사 조회 여부
 */
interface FavoriteListRequestParams {
  myFavoriteYn?: YesNo;
}

/**
 * POI 즐겨찾기 조회 API
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/35850299/06.+User+Favorite
 * @param params
 * @param config
 */
function getFavoriteList(params: FavoriteListRequestParams = {}, config: AxiosRequestConfig = {}) {
  const { myFavoriteYn = 'Y' } = params
  const { params: configParams, ...restConfig } = config
  return frontmanAxios.get<FavoritePoiListResData>('/flerken/api/v1/poi-favorite', {
    ...restConfig,
    params: {
      userKey: '$userKey',
      myFavoriteYn,
      ...configParams,
    },
  })
}

export { getFavoriteList }
