import { frontmanAxios } from '../../../axios'
import { AxiosRequestConfig } from 'axios'
import { GetTermsAgreementParams, GetTermsAgreementResData } from '../types'

/**
 * 특정 약관 항목 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPAUTH/pages/336986598/01.
 * @param params
 * @param config
 */
export function getTermsAgreement_v2(params: GetTermsAgreementParams, config: AxiosRequestConfig = {}) {
  const {termsType, termsAllowCode, loginCount = '0'} = params

  return frontmanAxios.get<GetTermsAgreementResData>(`/heimdall/heimdall-rmi/api/v1/user/$userKey/terms-type/${termsType}`, {
    ...config,
    params: {
      termsAllowCode,
      loginCount,
    }
  })
}
