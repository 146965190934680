import { MapContextProps, withMapContext } from '@/MapContext';
import {
    MapControlDockContextProps,
    withMapControlDockContext
} from '@/MapControlDockContext';
import { clearStyle, setStyle, splitClassName } from '@/utils/DomUtils';
import Vsm from '@vsm/vsm';
import isEqual from 'lodash.isequal';
import React, { CSSProperties, ReactNode } from 'react';
import { createRoot, Root } from 'react-dom/client';

type Props = MapContextProps &
    MapControlDockContextProps & {
        className?: string;
        style?: CSSProperties;
        defaultInteractive?: boolean;
        children?: ReactNode;
    };

class ReactBaseControl extends React.PureComponent<Props> {
    private readonly _container: Vsm.MapControls.MapControlContainer;
    private readonly _baseClassName: string;
    private _root: Root;

    public constructor(props: Props) {
        super(props);

        this._container = new Vsm.MapControls.MapControlContainer({
            interactive: props.defaultInteractive
        });

        const element = this._container.getContainer();
        this._baseClassName = element.className;
        this._root = createRoot(element);
    }

    public componentDidMount(): void {
        const { className, style } = this.props;

        this._setClassName(className);
        this._setStyle(style);

        this._addControl(this.props);
        this._renderToContainer();
    }

    private _setClassName(value?: string): void {
        const element = this._container.getContainer();
        element.className = this._baseClassName;

        if (value) {
            element.classList.add(...splitClassName(value));
        }
    }

    private _setStyle(value?: CSSProperties): void {
        const element = this._container.getContainer();
        clearStyle(element);

        if (value) {
            setStyle(element, value);
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.className !== this.props.className) {
            this._setClassName(this.props.className);
        }

        if (!isEqual(prevProps.style, this.props.style)) {
            this._setStyle(this.props.style);
        }

        if (
            prevProps.map !== this.props.map ||
            prevProps.dockName !== this.props.dockName ||
            prevProps.containerKey !== this.props.containerKey
        ) {
            this._removeControl(prevProps);
            this._addControl(this.props);
        }

        this._renderToContainer();
    }

    public componentWillUnmount(): void {
        this._removeControl(this.props);
    }

    private _addControl(props: Readonly<Props>): void {
        props.map?.addControl(this._container, props.dockName);
    }

    private _removeControl(props: Readonly<Props>): void {
        props.map?.removeControl(this._container);
    }

    private _renderToContainer(): void {
        this._root.render(<>{this.props.children}</>);
    }

    public render(): ReactNode {
        return null;
    }
}

export default withMapContext(withMapControlDockContext(ReactBaseControl));
