import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { GetBenefitListRequestParams, BenefitList } from './types'

/**
 * 보험사 목록 조회 (어드민과 같은 api 사용)
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/294657851/2023+API+Specification#%ED%98%9C%ED%83%9D-%EB%AA%A9%EB%A1%9D-%EC%A1%B0%ED%9A%8C
 * @param categoryId 조회 대상의 카테고리 ID
 * @param valid 현재 기준 게시일이 유효한 혜택 목록만 필터링 여부
 * @param config
 */

function getBenefitList({ categoryId, valid }: GetBenefitListRequestParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<BenefitList[]>(`/driving-score/bnf/benefits?categoryId=${categoryId}&valid=${valid}`, config)
}

export { getBenefitList }
