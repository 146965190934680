import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { TermsGroupDetailReqType, TermsGroupDetailResType } from './types'

/**
 * 약관 그룹 상세 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/FYJNOOBUUR/pages/289801087/M+ADMIN+-+-
 * @param params
 * @param config
 */

function getTermsGroupDetail(params: TermsGroupDetailReqType, config: AxiosRequestConfig = {}) {
  const { termsGroupCode, groupAppCode } = params

  return frontmanAxios.get<TermsGroupDetailResType>('/proxy/square-admin/user/api/terms/group/detail', {
    ...config,
    params: {
      termsGroupCode,
      groupAppCode,
    }
  })
}

export { getTermsGroupDetail }
