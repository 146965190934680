import React, { createContext, PropsWithChildren, useContext } from 'react'

export interface Config {
  customized?: boolean;
  baseFontSize?: string;
  baseTextColor?: string;
  baseBackgroundColor?: string;
  onCopyText?: (text: string) => void;
  onToastMessage?: (text: string) => void;
}

type Props = PropsWithChildren<{
  config?: Config;
}>

const Context = createContext(null as (Config | null))

function ConfigProvider(props: Props) {
  const { config = null, children } = props
  return (
    <Context.Provider value={config}>
      {children}
    </Context.Provider>
  )
}

function useConfig() {
  return useContext(Context)
}

export { ConfigProvider, useConfig }
