import { useEffect, useRef } from 'react'

export function useEffectOnce(hook: () => (() => void) | void) {
  const flag = useRef(false)

  useEffect(() => {
    if (flag.current) {
      return
    }
    flag.current = true
    return hook()
  }, [hook])
}
