import { frontmanAxios } from "../../axios";
import { AxiosRequestConfig } from "axios";
import qs from "qs";
import { UbPayUnpaidTolls } from "../../../carlife";

/**
 * 미납통행료 텍스트 복호화
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPEVENT/pages/658770532/SquareAdmin+-+-#02.-%EB%AF%B8%EB%82%A9%ED%86%B5%ED%96%89%EB%A3%8C-%ED%85%8D%EC%8A%A4%ED%8A%B8-%EB%B3%B5%ED%98%B8%ED%99%94
 * @param encryptedText
 * @param config
 */

function getUbpayDecryptData<R>(encryptedText: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.post<R>('/square-admin/user/api/partners/expay/decrypt', { text: encryptedText }, config)
}

export { getUbpayDecryptData }