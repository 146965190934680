import gte from 'semver/functions/gte'
import lt from 'semver/functions/lt'
import { BaseInterfaceImpl } from './BaseInterfaceImpl'
import { TmapAppInterfaceParams } from './internal-types'
import { TmapApp } from './TmapApp'
import { TmapAppInterface } from './TmapAppInterface'
import {
  CIAuthResult,
  FavoriteToggleResult,
  FavoriteYesNoResult,
  LoginMethodType,
  PaymentActivityResult,
  PickContactResult,
  PoiDataJson,
  PointActivityResult,
  QRCodeScanResult,
  RedDotListData,
  SearchResult,
  ShowSearchResult,
  SubwayRouteData,
  SubwayRouteDetailsResult,
  SubwayRouteResult,
  TIDAuthResult,
  TipOffList,
  TmapInfoObject,
  TmapMapContext,
  TransportFavoriteToggleResult,
  TransportFavoriteYesNoResult,
  Wgs84Coordinate,
} from './types'
import {
  addNativeCallback,
  applyUrlEncodedProperties,
  PREFIX_CALLBACK_ID,
  safetyAsyncCall,
  safetyCall,
  transformTipOffList,
} from './utils'

declare global {
  interface Window {
    // @ts-ignore
    TmapApp: { [name: string]: (...args: any[]) => any };
  }
}

/**
 * callbackId 가 지정된 것들에 대한 설명.
 * 다른 웹뷰를 여는 api는 사용자가 웹뷰를 닫아야 callbackJS 호출하기 때문에 timeout 처리 불가능.
 * 동기적으로 여러번 호출할수 있는 구조가 아니라서 callbackId 고정.
 */
export class TmapAndroidInterfaceImpl extends BaseInterfaceImpl implements TmapAppInterface {

  private androidInterface: typeof window.TmapApp

  constructor(tmapApp: TmapApp) {
    super(tmapApp)

    if (window.TmapApp) {
      this.androidInterface = window.TmapApp
    } else {
      this.androidInterface = new Proxy({}, {
        get: (target, prop) => {
          return (...args: any[]) => {
            this.log(prop, JSON.stringify(args))
          }
        },
      })
    }
  }

  makeToast({ msg }: TmapAppInterfaceParams['makeToast']) {
    this.androidInterface.makeToast(msg)
  }

  makeDialogPopup({
    msg, title = '', cancel = '취소', confirm = '확인',
  }: TmapAppInterfaceParams['makeDialogPopup']) {
    return new Promise<boolean>(resolve => {
      /**
       * 프로퍼티 전체 경로 대신 콜백 펑션 이름 부분만 사용.
       * 앱은 TmapWebView.callback을 실행하고 TmapWebView.callback 펑셩 내부에서 callJS 값을 펑션 이름으로 펑션 호출함.
       * {@link TmapAppInterface#makeDialogPopup} 참고
       */
      const callJS = addNativeCallback(resolve)
      this.androidInterface.makeDialogPopup(title, msg, cancel, confirm, callJS.replace(PREFIX_CALLBACK_ID, ''))
    })
  }

  onBackKeyPressed({ errorCode = '', errorMsg = '' }: TmapAppInterfaceParams['onBackKeyPressed'] = {}) {
    this.androidInterface.onBackKeyPressed(errorCode, errorMsg)
  }

  selectImage() {
    return safetyAsyncCall<(Blob & { name: string } | null)>(callbackJS => {
      this.androidInterface.selectImage(callbackJS)
    }, {
      resolver: async (resolve) => {
        try {
          const path = this.androidInterface.getImagePath()
          const base64 = this.androidInterface.getImageData()
          const blob = (await fetch(`data:image/png;base64,${base64}`).then(response =>
            response.blob(),
          )) as Blob & { name: string }
          blob.name = path.split('/').pop()
          resolve(blob)
        } catch {
          resolve(null)
        }
      },
      callbackId: 'selectImageCallback',
      timeout: null,
    })
  }

  openBrowser({ url }: TmapAppInterfaceParams['openBrowser']) {
    this.androidInterface.openBrowser(encodeURIComponent(url))
  }

  phoneCall({ strTel }: TmapAppInterfaceParams['phoneCall']) {
    this.androidInterface.phoneCall(encodeURIComponent(strTel))
  }

  async getTipOffList() {
    return safetyCall(
      () => {
        const rawData = this.androidInterface.getTipOffList()
        return transformTipOffList(rawData)
      },
      { result: [] } as TipOffList,
    )
  }

  deleteTipOff({ id }: TmapAppInterfaceParams['deleteTipOff']) {
    this.androidInterface.deleteTipOff(id)
  }

  search({ keyword, searchFrom = 'regist' }: TmapAppInterfaceParams['search']) {
    return safetyAsyncCall<SearchResult>(callbackJS => {
      if (gte(this.tmapApp.env.appVersion, '9.10.0')) {
        this.androidInterface.search(keyword, callbackJS, searchFrom)
      } else {
        this.androidInterface.search(keyword, callbackJS)
      }
    }, {
      resolver: (resolve, ...result) => resolve(result as SearchResult),
      callbackId: 'searchCallback',
      timeout: null,
    })
  }

  showSearch({ keyword, extra = '' }: TmapAppInterfaceParams['showSearch']) {
    return safetyAsyncCall<ShowSearchResult>(callbackJS => {
      this.androidInterface.showSearch(keyword, callbackJS, extra)
    }, {
      resolver: (resolve, ...result) => resolve(result as ShowSearchResult),
      callbackId: 'showSearchCallback',
      timeout: null,
    })
  }

  setSearchResult({ poiDataJson }: TmapAppInterfaceParams['setSearchResult']) {
    safetyCall(() => this.androidInterface.setSearchResult(JSON.stringify(poiDataJson)))
  }

  searchSubway({ keyword, cityCode, cityName }: TmapAppInterfaceParams['searchSubway']) {
    return safetyAsyncCall<SubwayRouteResult>((callbackJS) => {
      this.androidInterface.searchSubway(keyword, cityCode, cityName, callbackJS)
    }, {
      resolver: (resolve, ...result) => {
        resolve({
          result: result[0] === 'true',
          data: result[1] ? JSON.parse(result[1]) : [],
        })
      },
      callbackId: 'searchSubwayCallback',
      defaultValue: { result: false, data: [] },
      timeout: null,
    })
  }

  async getRecentDestination({ count }: TmapAppInterfaceParams['getRecentDestination']) {
    return safetyCall(() => {
      const data = this.androidInterface.getRecentDestination(count)
      return JSON.parse(data)
    }, { result: [] })
  }

  registPoi({ type, pkey = null, poiId = null, navSeq = null, poiName = null }: TmapAppInterfaceParams['registPoi']) {
    safetyCall(() => this.androidInterface.registPoi(type, pkey, poiId, navSeq, poiName))
  }

  showPoiDetailInfo({
    poiId,
    navX = null,
    navY = null,
    pkey = null,
    tailParam,
  }: TmapAppInterfaceParams['showPoiDetailInfo']) {
    safetyCall(() => {
      if (gte(this.env.appVersion, '9.0.0')) {
        this.androidInterface.showPoiDetailInfo(poiId, navX, navY, pkey, tailParam ? JSON.stringify(tailParam) : null)
      } else if (gte(this.env.appVersion, '8.11.0')) {
        this.androidInterface.showPoiDetailInfo(poiId, navX, navY, pkey)
      } else {
        this.androidInterface.showPoiDetailInfo(poiId)
      }
    })
  }

  copyClipboard({ label, content }: TmapAppInterfaceParams['copyClipboard']) {
    safetyCall(() => this.androidInterface.copyClipboard(label, content))
  }

  updateAccessKey({ key }: TmapAppInterfaceParams['updateAccessKey']) {
    this.androidInterface.updateAccessKey(key)
  }

  clearCache() {
    this.androidInterface.clearCache()
  }

  notifyChangedUserName({ userName }: TmapAppInterfaceParams['notifyChangedUserName']) {
    safetyCall(() => this.androidInterface.notifyChangedUserName(userName))
  }

  openOilDiscount(args: TmapAppInterfaceParams['openOilDiscount']) {
    if (gte(this.env.appVersion, '8.1.0')) {
      this.androidInterface.openOilDiscount(args?.pagId || null)
    } else {
      this.openBrowser({ url: 'tmap://setting-discount' })
    }
  }

  openNearBy(args?: TmapAppInterfaceParams['openNearBy']) {
    safetyCall(() => this.androidInterface.openNearBy(args?.reqKey || null))
  }

  async getAccessKey() {
    return safetyCall(() => this.androidInterface.getAccessKey(), '')
  }

  notifyChangedInfo({ type }: TmapAppInterfaceParams['notifyChangedInfo']) {
    this.androidInterface.notifyChangedInfo(type)
  }

  getDisplayInfo({ $timeout = 300 }: TmapAppInterfaceParams['getDisplayInfo'] = {}) {
    return safetyAsyncCall(callbackJS => {
      this.androidInterface.getDisplayInfo(callbackJS)
    }, {
      resolver: (resolve, data) => {
        try {
          resolve(JSON.parse(data))
        } catch {
          resolve(null)
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('8.2.0', $timeout),
    })
  }

  async getDeviceId() {
    return safetyCall(() => this.androidInterface.getDeviceId(), '')
  }

  async getCarrierName() {
    return safetyCall(() => this.androidInterface.getCarrierName(), '')
  }

  async getAppSyncApiKey() {
    return safetyCall(() => this.androidInterface.getAppSyncApiKey(), '')
  }

  async getEUK() {
    return safetyCall(() => this.androidInterface.getEUK(), '')
  }

  openServiceByName<Data>({
    serviceName,
    jsonData,
    $encodeJsonData = lt(this.env.appVersion, '10.0.0'),
  }: TmapAppInterfaceParams['openServiceByName']) {
    const data = jsonData ? JSON.stringify($encodeJsonData ? applyUrlEncodedProperties(jsonData) : jsonData) : null
    if (lt(this.env.appVersion, '10.0.0')) {
      safetyCall(() => this.androidInterface.openServiceByName(serviceName, data))
      return Promise.resolve(null as Data)
    } else {
      // 열린 웹뷰에서 closeAndReturnData 호출해줘야 callbackFunctionName 호출하기 때문에 timeout 처리 불가능.
      // 동기적으로 여러번 호출할수 있는 구조가 아니라서 callbackId 고정.
      return safetyAsyncCall<Data>(callbackJS => {
        this.androidInterface.openServiceByName(serviceName, data, callbackJS)
      }, {
        resolver: (resolve, result) => {
          // closeAndReturnData 참고.
          const decodedCallbackData = decodeURIComponent(result)
          try {
            resolve(JSON.parse(decodedCallbackData))
          } catch {
            resolve(decodedCallbackData as Data)
          }
        },
        callbackId: 'openServiceByNameCallback',
        timeout: null,
      })
    }
  }

  openServiceByUrl({
    url,
    title,
    cacheControl,
    portraitonly,
    useStatusBarArea,
  }: TmapAppInterfaceParams['openServiceByUrl']) {
    // 열린 웹뷰가 닫혀야 callbackJS 호출하기 때문에 timeout 처리 불가능.
    // 동기적으로 여러번 호출할수 있는 구조가 아니라서 callbackId 고정.
    return safetyAsyncCall<[string, string]>(callbackJS => {
      if (gte(this.env.appVersion, '10.0.0')) {
        this.androidInterface.openServiceByUrl(url, title ?? null, callbackJS, cacheControl ?? true, portraitonly ?? true, useStatusBarArea ? 'Y' : 'N')
      } else if (gte(this.env.appVersion, '9.10.0')) {
        this.androidInterface.openServiceByUrl(url, title ?? null, callbackJS, cacheControl ?? true, portraitonly ?? true)
      } else if (gte(this.env.appVersion, '8.11.0')) {
        this.androidInterface.openServiceByUrl(url, title ?? null, callbackJS, cacheControl ?? true)
      } else {
        this.androidInterface.openServiceByUrl(url, title ?? null, callbackJS)
      }
    }, {
      resolver: (resolve, ...result) => resolve(result as [string, string]),
      callbackId: 'openServiceByUrlCallback',
      timeout: null,
    })
  }

  showNativeTitle({ show, title = null }: TmapAppInterfaceParams['showNativeTitle']) {
    safetyCall(() => this.androidInterface.showNativeTitle(show, title))
  }

  playTTS({ guideType, cdn, ttsMessage }: TmapAppInterfaceParams['playTTS']) {
    // tts 플레이타임을 알수없기 때문에 timeout 처리 불가능.
    return safetyAsyncCall<void>(callJS => {
      this.androidInterface.playTTS(guideType, cdn, ttsMessage, callJS)
    }, {
      timeout: null,
    })
  }

  stopTTS() {
    safetyCall(() => this.androidInterface.stopTTS())
  }

  recordEvent({ name, json }: TmapAppInterfaceParams['recordEvent']) {
    safetyCall(() => this.androidInterface.recordEvent(name, json ? JSON.stringify(json) : '{}'))
  }

  async getUserSetting({ key }: TmapAppInterfaceParams['getUserSetting']) {
    return safetyCall<string>(() => {
      const value = this.androidInterface.getUserSetting(key)
      if (value == null) {
        return ''
      }
      return String(value)
    }, '')
  }

  shareMessage({ title, body, url }: TmapAppInterfaceParams['shareMessage']) {
    safetyCall(() => this.androidInterface.share(title, body, url))
  }

  share({ poiDataJson }: TmapAppInterfaceParams['share']) {
    safetyCall(() => this.androidInterface.share(JSON.stringify(poiDataJson)))
  }

  showGNB({ show }: TmapAppInterfaceParams['showGNB']) {
    safetyCall(() => this.androidInterface.showGNB(show))
  }

  getCurrentPosition({ $timeout = 10000 }: TmapAppInterfaceParams['getCurrentPosition'] = {}) {
    return safetyAsyncCall<Wgs84Coordinate | null>(callbackJS => {
      this.androidInterface.getCurrentPosition(callbackJS)
    }, {
      resolver: (resolve, ...coordinates) => {
        if (coordinates[0] == null) {
          resolve(null)
        } else {
          resolve(coordinates as Wgs84Coordinate)
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('8.0.0', $timeout),
    })
  }

  async getLoginMethod() {
    return safetyCall<LoginMethodType | null>(() => this.androidInterface.getLoginMethod(), null)
  }

  requestTidLogin() {
    return safetyAsyncCall<TIDAuthResult>(callbackJS => {
      this.androidInterface.requestTidLogin(callbackJS)
    }, {
      resolver: (resolve, ...result) => resolve(result as TIDAuthResult),
      callbackId: 'requestTidLoginCallback',
      timeout: null,
    })
  }

  requestConnectCi() {
    return safetyAsyncCall<TIDAuthResult>(callbackJS => {
      this.androidInterface.requestConnectCi(callbackJS)
    }, {
      resolver: (resolve, ...result) => resolve(result as TIDAuthResult),
      callbackId: 'requestConnectCiCallback',
      timeout: null,
    })
  }

  startReportLocation({ url }: TmapAppInterfaceParams['startReportLocation']) {
    safetyCall(() => this.androidInterface.startReportLocation(url))
  }

  stopReportLocation() {
    safetyCall(() => this.androidInterface.stopReportLocation())
  }

  startPaymentActivity({ url }: TmapAppInterfaceParams['startPaymentActivity']) {
    return safetyAsyncCall<PaymentActivityResult>(callbackJS => {
      this.androidInterface.startPaymentActivity(url, callbackJS)
    }, {
      resolver: (resolve, ...result) => resolve([result[0] || '', result[1] || '']),
      callbackId: 'startPaymentActivityCallback',
      timeout: null,
    })
  }

  startPointActivity({ path }: TmapAppInterfaceParams['startPointActivity']) {
    return safetyAsyncCall<PointActivityResult>(callbackJS => {
      this.androidInterface.startPointActivity(path, callbackJS)
    }, {
      resolver: (resolve, ...result) => resolve([result[0] || '', result[1] || '']),
      callbackId: 'startPointActivityCallback',
      timeout: null,
    })
  }

  handleBackKeyEventFromWeb({ handleFromWeb }: TmapAppInterfaceParams['handleBackKeyEventFromWeb']) {
    safetyCall(() => this.androidInterface.handleBackKeyEventFromWeb(handleFromWeb))
  }

  selectBottomNavigationItem({ tabName }: TmapAppInterfaceParams['selectBottomNavigationItem']) {
    safetyCall(() => this.androidInterface.selectBottomNavigationItem(tabName))
  }

  setBottomNavigationVisibility({ isShow }: TmapAppInterfaceParams['setBottomNavigationVisibility']) {
    safetyCall(() => this.androidInterface.setBottomNavigationVisibility(isShow))
  }

  setOrientation({ isPortrait }: TmapAppInterfaceParams['setOrientation']) {
    safetyCall(() => this.androidInterface.setOrientation(isPortrait))
  }

  async isRoadAddressType() {
    return safetyCall(() => this.androidInterface.isRoadAddressType(), false)
  }

  clearPushHistory({ pushGroupId }: TmapAppInterfaceParams['clearPushHistory']) {
    safetyCall(() => this.androidInterface.clearPushHistory(pushGroupId))
  }

  setAsumUserInfo() {
    safetyCall(() => this.androidInterface.setAsumUserInfo())
  }

  getRedDotList({ $timeout = 10000 }: TmapAppInterfaceParams['getRedDotList'] = {}) {
    return safetyAsyncCall<RedDotListData[]>(callbackJS => {
      this.androidInterface.getRedDotList(callbackJS)
    }, {
      resolver: (resolve, result) => {
        try {
          resolve(JSON.parse(result) || [])
        } catch {
          resolve([])
        }
      },
      defaultValue: [],
      timeout: this.selectTimeout('9.7.0', $timeout),
    })
  }

  updateRedDotList({ updateData }: TmapAppInterfaceParams['updateRedDotList']) {
    safetyCall(() => this.androidInterface.updateRedDotList(JSON.stringify(updateData)))
  }

  sendMomentHappen({ momentCode, importData }: TmapAppInterfaceParams['sendMomentHappen']) {
    safetyCall(() => this.androidInterface.sendMomentHappen(momentCode, JSON.stringify(importData)))
  }

  getDeviceAdId({ $timeout = 300 }: TmapAppInterfaceParams['getDeviceAdId'] = {}) {
    return safetyAsyncCall(callbackJS => this.androidInterface.getDeviceAdId(callbackJS), {
      defaultValue: '',
      timeout: this.selectTimeout('9.1.0', $timeout),
    })
  }

  async getDeviceServiceVendorId() {
    return ''
  }

  getCurrentMapContext({ $timeout = 300 }: TmapAppInterfaceParams['getCurrentMapContext'] = {}) {
    return safetyAsyncCall<TmapMapContext | null>(callbackJS => this.androidInterface.getCurrentMapContext(callbackJS), {
      resolver: (resolve, ...result) => {
        if (result[0] == null) {
          resolve(null)
        } else {
          resolve(result as TmapMapContext)
        }
      },
      defaultValue: null,
      timeout: this.selectTimeout('9.1.0', $timeout),
    })
  }

  setCurrentMapContext({
    x, y, tiltAngle, rotationAngle, zoomLevel,
  }: TmapAppInterfaceParams['setCurrentMapContext']) {
    safetyCall(() => this.androidInterface.setCurrentMapContext(x, y, tiltAngle, rotationAngle, zoomLevel))
  }

  async getWebViewMountTime() {
    return safetyCall(() => this.androidInterface.getWebViewMountTime(), -1)
  }

  async getSessionId() {
    return safetyCall(() => this.androidInterface.getSessionId(), '')
  }

  getLastPosition({ $timeout = 5000 }: TmapAppInterfaceParams['getLastPosition'] = {}) {
    return safetyAsyncCall<Wgs84Coordinate | null>(callbackJS => this.androidInterface.getLastPosition(callbackJS), {
      resolver: (resolve, ...coordinates) => {
        if (coordinates[0] == null) {
          resolve(null)
        } else {
          resolve(coordinates as Wgs84Coordinate)
        }
      },
      defaultValue: null,
      timeout: $timeout,
    })
  }

  async getPhoneNumber() {
    return safetyCall(() => this.androidInterface.getPhoneNumber(), null)
  }

  requestCILogin() {
    return safetyAsyncCall<CIAuthResult>(callbackJS => {
      this.androidInterface.requestCILogin(callbackJS)
    }, {
      resolver: (resolve, ...result) => resolve(result as CIAuthResult),
      callbackId: 'requestCILoginCallback',
      timeout: null,
    })
  }

  requestCIValidation() {
    return safetyAsyncCall<CIAuthResult>(callbackJS => {
      this.androidInterface.requestCIValidation(callbackJS)
    }, {
      resolver: (resolve, ...result) => resolve(result as CIAuthResult),
      callbackId: 'requestCIValidationCallback',
      timeout: null,
    })
  }

  startQrCodeScanActivity({ title, showBottom = false }: TmapAppInterfaceParams['startQrCodeScanActivity']) {
    return safetyAsyncCall<QRCodeScanResult>(callbackJS => {
      if (gte(this.env.appVersion, '8.13.0')) {
        this.androidInterface.startQrCodeScanActivity(title, showBottom, callbackJS)
      } else {
        this.androidInterface.startQrCodeScanActivity(title, callbackJS)
      }
    }, {
      resolver: (resolve, ...result) => resolve(result as QRCodeScanResult),
      callbackId: 'startQrCodeScanActivityCallback',
      timeout: null,
    })
  }

  async getTmapInfo() {
    return safetyCall<TmapInfoObject | null>(() => {
      const tmapInfo = JSON.parse(this.androidInterface.getTmapInfo())
      const keys = Object.keys(tmapInfo) as (keyof TmapInfoObject)[]
      return keys.reduce((obj, key) => {
        obj[key] = String(tmapInfo[key])
        return obj
      }, {} as TmapInfoObject)
    }, null)
  }

  async getRemoteConfig({ key }: TmapAppInterfaceParams['getRemoteConfig']) {
    return safetyCall(() => {
      return this.androidInterface.getRemoteConfig(key)
    }, '')
  }

  openFavoriteRoute() {
    safetyCall(() => this.androidInterface.openFavoriteRoute())
  }

  async getFavoriteList({ count = 5 }: TmapAppInterfaceParams['getFavoriteList'] = {}) {
    return safetyCall<{ result: PoiDataJson[] }>(() => {
      const result = this.androidInterface.getFavoriteList(count)
      return JSON.parse(result)
    }, { result: [] })
  }

  useStatusBarArea({ use }: TmapAppInterfaceParams['useStatusBarArea']) {
    safetyCall(() => this.androidInterface.useStatusBarArea(use))
  }

  setStatusBarTextColor({ color }: TmapAppInterfaceParams['setStatusBarTextColor']) {
    safetyCall(() => this.androidInterface.setStatusBarTextColor(color))
  }

  closeAndReturnData(args?: TmapAppInterfaceParams['closeAndReturnData']) {
    const argData = args?.callbackData
    const callbackData = argData == null ? null : argData
    // 모든 타입 값을 받으므로 일괄 json 스트링 변환한뒤 이스케이프 시퀀스 손실을 막기 위해 url인코딩.
    // 안드로이드는 내부에서 url디코딩 한번 일어나기 때문에 url인코딩된 결과를 받으려면 두번 인코딩 해야됨.
    const encodedCallbackData = encodeURIComponent(encodeURIComponent(JSON.stringify(callbackData)))
    safetyCall(() => this.androidInterface.closeAndReturnData(encodedCallbackData))
  }

  pickContact() {
    return safetyAsyncCall<PickContactResult | null>(callbackJS => {
      this.androidInterface.pickContact(callbackJS)
    }, {
      resolver: (resolve, result) => {
        try {
          resolve(JSON.parse(result))
        } catch {
          resolve(null)
        }
      },
      callbackId: 'pickContactCallback',
      timeout: null,
    })
  }

  showSoftKeyboard({ show }: TmapAppInterfaceParams['showSoftKeyboard']) {
    safetyCall(() => {
      this.androidInterface.showSoftKeyboard(show)
    })
  }

  openInAppBrowser({ bottombar, ...args }: TmapAppInterfaceParams['openInAppBrowser']) {
    safetyCall(() => {
      this.androidInterface.openInAppBrowser(
        JSON.stringify({
          ...args,
          bottombar: bottombar == null ? undefined : bottombar ? 'Y' : 'N',
        }),
      )
    })
  }

  toggleFavorite({ poiDataJson }: TmapAppInterfaceParams['toggleFavorite']) {
    return safetyAsyncCall<FavoriteToggleResult>(callbackJS => {
      this.androidInterface.toggleFavorite(JSON.stringify(poiDataJson), callbackJS)
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'toggleFavoriteCallback',
      timeout: null,
    })
  }

  getFavoriteState({ poiDataJson }: TmapAppInterfaceParams['getFavoriteState']) {
    return safetyAsyncCall<FavoriteYesNoResult>(callbackJS => {
      this.androidInterface.getFavoriteState(JSON.stringify(poiDataJson), callbackJS)
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'getFavoriteStateCallback',
      timeout: null,
    })
  }

  togglePublicTransportFavorite({ poiDataJson }: TmapAppInterfaceParams['togglePublicTransportFavorite']) {
    return safetyAsyncCall<TransportFavoriteToggleResult>(callbackJS => {
      this.androidInterface.togglePublicTransportFavorite(JSON.stringify(poiDataJson), callbackJS)
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'togglePublicTransportFavoriteCallback',
      defaultValue: { result: false },
      timeout: null,
    })
  }

  getPublicTransportFavoriteState({ poiDataJson }: TmapAppInterfaceParams['getPublicTransportFavoriteState']) {
    return safetyAsyncCall<TransportFavoriteYesNoResult>(callbackJS => {
      this.androidInterface.getPublicTransportFavoriteState(JSON.stringify(poiDataJson), callbackJS)
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'getPublicTransportFavoriteStateCallback',
      defaultValue: { result: false },
      timeout: null,
    })
  }

  openSubwayRouteDetail({ data }: TmapAppInterfaceParams['openSubwayRouteDetail']) {
    return safetyAsyncCall<SubwayRouteDetailsResult | null>(callbackJS => {
      this.androidInterface.openSubwayRouteDetail(JSON.stringify(data), callbackJS)
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'openSubwayRouteDetailCallback',
      defaultValue: null,
      timeout: null,
    })
  }

  getNearestSubway() {
    return safetyAsyncCall<SubwayRouteData | null>(callbackJS => {
      this.androidInterface.getNearestSubway(callbackJS)
    }, {
      resolver: (resolve, result) => {
        resolve(JSON.parse(result))
      },
      callbackId: 'getNearestSubwayCallback',
      defaultValue: null,
      timeout: null,
    })
  }

  saveSubwayRoute({ data }: TmapAppInterfaceParams['saveSubwayRoute']) {
    safetyCall(() => this.androidInterface.saveSubwayRoute(JSON.stringify(data)))
  }

  getWebPolicy<Data>({ key }: TmapAppInterfaceParams['getWebPolicy']) {
    return safetyAsyncCall<Data | null>(callbackJS => {
      this.androidInterface.getWebPolicy(key, callbackJS)
    }, {
      resolver: (resolve, result) => {
        try {
          resolve(JSON.parse(result))
        } catch {
          resolve(result)
        }
      },
      defaultValue: null,
      timeout: null,
    })
  }
}
