import formatDateTime from 'date-fns/format';
import { EscApiBodyHeader, getKSTNow } from '../../../common';
import {
  BaseCarInfo,
  OuterCarInfoResultCode,
  OuterCarInfoSeries,
} from '../../../esc-proxy';
import { frontmanAxios } from '../../axios';
import { AxiosRequestConfig } from 'axios'

export interface OuterCarInfoParam {
  carInfo?: BaseCarInfo;
  ts_key?: string;
  seriesno?: string;
  mode?: 'detail' | 'search';
  mymileage?: string;
  reqTime?: number;
}

export interface OuterCarInfoRefetchResponseData {
  header: EscApiBodyHeader;
  extCarInfo: {
    imgUrl: string;
    carGubun: string;
    seriesList: OuterCarInfoSeries[];
    color: string | null;
    buyYn: string | null;
    resultCode: OuterCarInfoResultCode;
    selOptList:
      | {
          optname: string;
          optprice: string;
        }[]
      | null;
    selOptname: string;
    selOptprice: string;
    resultMsg: string;
  };
}

/**
 * 차량 정보 외부 조회 (재조회용)
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPNDDS/pages/341938922/ESC+1.1.14.+-+-+V2?atlOrigin=eyJpIjoiYTZmMzZiYWRkYTJjNGQ1NGIxYmYwNDhkMTc5NzU0Y2IiLCJwIjoiYyJ9
 * @param {OuterCarInfoParam} props
 * @description 신규 조회시에는 carInfo 값이 필수. 재 조회 시에는 해당 값이 필수: ts_key, seriesno, mode: 'detail'
 * @param config
 */
function getOuterCarInfoForRefetch(props: OuterCarInfoParam, config: AxiosRequestConfig = {}) {
  const { carInfo, ts_key, seriesno, mode, mymileage, reqTime } = props;
  const { headers: configHeaders, ...restConfig } = config;

  let reqTimeout = 30000;
  if (reqTime && !isNaN(reqTime)) {
    reqTimeout = Number(reqTime);
  }
  const requestTime = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss');
  return frontmanAxios.post<OuterCarInfoRefetchResponseData>(
    '/esc/esc-channel/profile/outercarinfo/find/carinfo/v2',
    {
      header: {
        reqTime: requestTime,
      },
      carInfo,
      ts_key,
      seriesno,
      mode,
      mymileage,
    },
    {
      ...restConfig,
      headers: {
        Accept: 'application/json',
        ...configHeaders
      },
      timeout: reqTimeout,
    }
  );
}

function isOuterCarInfoRefetchRequestSucceed(code: OuterCarInfoResultCode) {
  return code === '0' || code === '000000';
}

export { getOuterCarInfoForRefetch, isOuterCarInfoRefetchRequestSucceed };
