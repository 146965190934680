import { AxiosRequestConfig } from 'axios'
import { OptionTypeCode, CodeListResData } from '../../../common'
import { frontmanAxios } from '../../axios'

interface CarInfoCodeListRequestParams {
  type: OptionTypeCode;
}

/**
 * 자동차 브랜드 코드 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/35849601/02.+Car+Codes#id-02.CarCodes-1.%EC%BD%94%EB%93%9C%EB%AA%A9%EB%A1%9D%EC%A1%B0%ED%9A%8C(Version1)
 * @param params
 * @param config
 */
function getCarOptionCodeList(params: CarInfoCodeListRequestParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<CodeListResData>(`/flerken/api/v1/code/groups/00/types/${params.type}`, config)
}

export { getCarOptionCodeList }
