import { MapContextProps, withMapContext } from '@/MapContext';
import MapControlDockContext from '@/MapControlDockContext';
import Vsm from '@vsm/vsm';
import React, { ReactNode } from 'react';

type Props = MapContextProps & {
    name?: Vsm.MapControlDockName | string;
    container?: HTMLElement | null;
    children?: ReactNode;
};

type State = {
    containerKey: string;
};

const RESERVED_DOCK_NAMES = new Set<string>(
    Object.values(Vsm.Map.ControlDockNames)
);

class MapControlDock extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            containerKey: `dock-context-container-${Date.now()}`
        };
    }

    public componentDidMount(): void {
        this._update();
    }

    public getSnapshotBeforeUpdate(prevProps: Readonly<Props>): any {
        if (
            prevProps.map !== this.props.map ||
            prevProps.name !== this.props.name ||
            prevProps.container !== this.props.container
        ) {
            this._update();
        }

        return null;
    }

    public componentDidUpdate(): void {}

    private _update(): void {
        const dockName = this.props.name || Vsm.Map.ControlDockNames.Default;
        const dockContainer = this.props.container || null;

        if (!RESERVED_DOCK_NAMES.has(dockName)) {
            this.props.map?.setControlDock(dockName, dockContainer);
            this.setState({
                containerKey: `dock-context-container-${Date.now()}`
            });
        }
    }

    public render(): ReactNode {
        const { name, children } = this.props;
        const { containerKey } = this.state;

        return (
            <MapControlDockContext.Provider
                value={{
                    dockName: name || Vsm.Map.ControlDockNames.Default,
                    containerKey
                }}
            >
                {children}
            </MapControlDockContext.Provider>
        );
    }
}

export default withMapContext(MapControlDock);
