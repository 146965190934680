import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { ReportsResponseData } from "./types"

/**
 * 종합 리포트 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/550306443
 * @param config
 * 데이터가 없는 경우 빈객체 리턴({})
 */

function getReports(config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<ReportsResponseData>(`/ubds/trip-report/v1/reports/$userKey`, config)
}

export { getReports }
