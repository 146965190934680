import React, { ButtonHTMLAttributes } from 'react'
import TriggerIcon from './icon.svg'
import { TriggerButton } from './styled-components'

export function DevToolTriggerButton({ type, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <TriggerButton {...props} type={type}>
      <TriggerIcon/>
    </TriggerButton>
  )
}
