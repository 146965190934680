import styled, { AnyStyledComponent } from 'styled-components'

export const typeSmall = (c: AnyStyledComponent) => styled(c)`
  font-size: 0.9em;
`

export const typeBreakAll = (c: AnyStyledComponent) => styled(c)`
  word-break: break-all;
`

export const typePreserveWrap = (c: AnyStyledComponent) => styled(c)`
  white-space: pre-wrap;
  word-break: normal;
`

export const Root = styled.div<{ baseFontSize?: string; baseTextColor?: string; baseBackgroundColor?: string }>`
  font-size: ${props => props.baseFontSize ?? '16px'};
  color: ${props => props.baseTextColor ?? '#000'};
  background-color: ${props => props.baseBackgroundColor ?? '#fff'};
  overflow: auto;
`

export const PanelAnchor = styled.div`
  position: fixed;
  z-index: 10000000;
  overflow: visible;
  left: 0;
  bottom: 0;
`

export const Panel = styled.div`
  position: relative;
  z-index: 1;
`

export const DefaultPanel = styled(Panel)<{ baseFontSize?: string; baseTextColor?: string; baseBackgroundColor?: string }>`
  background-color: ${props => props.baseBackgroundColor ?? '#fff'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 32px;
  margin: 5px;
  border-radius: 4px;
  overflow: auto;
`

export const TriggerButton = styled.button`
  display: block;
  
  > svg {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .15);
    overflow: visible;
    vertical-align: middle;
  }
`

export const TriggerButtonWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
`

export const Button = styled.button<{activated?:boolean}>`
  display: inline-block;
  padding: 3px 6px;
  background: ${props => props.activated ? 'rgb(0, 122, 255)': '#f8f8fb'};
  border: #eaeaea 1px solid;
  border-radius: 4px;
  font-size: 1em;
  color: ${props => props.activated ? '#fff' : 'rgb(0, 122, 255)'};
  text-align: center;
  & + & {
    margin-left: 5px;
  }

  &:active {
    background: #eaeaea;
    color: rgb(0, 122, 255);
  }
`

export const Pre = typeSmall(typeBreakAll(typePreserveWrap(styled.pre`
  margin: 0;
`)))

export const Input = styled.input`
  appearance: none;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 3px 4px;
`

export const TextArea = styled.textarea`
  appearance: none;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  padding: 3px 4px;
`

export const Text = typeBreakAll(styled.span``)

export const Row = styled.li`
  border-bottom: 1px solid #f1f1f1;
  padding: 10px;
  display: flex;
  flex-direction: column;
`

export const Description = styled.p`
  font-size: 0.9em;
  margin-top: 5px;
  color: #6e7685;
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const ItemLabel = styled.span`
  display: inline-block;
  background: #e8effb;
  line-height: 1.6;
  border-radius: 4px;
  font-size: 1em;
  color: #0064ff;
  padding: 0 6px;
`
