import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { CharanchaMediaList, CharanchaMediaListParams } from "./types";

/**
 * 차란차 미디어 리스트 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/572817800/API#1.--%EB%AF%B8%EB%94%94%EC%96%B4-%EB%A6%AC%EC%8A%A4%ED%8A%B8-%EC%A1%B0%ED%9A%8C-API
 * @param params
 * @param config
 */
function getCharanchaMediaList({ size, type }: CharanchaMediaListParams, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<CharanchaMediaList>('/charancha/v1/media', {
    ...config,
    params: {
      size,
      type,
    }
  })
}

export { getCharanchaMediaList }
