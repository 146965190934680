import { frontmanAxios } from '../../../axios'
import { AxiosRequestConfig } from 'axios'
import { GetTermsAgreementResData, TermsAgreements } from '../types'

interface UpdateTermsAgreementRequestParams {
  termsType?: string
  termsAgreements: Pick<TermsAgreements, 'allowCode' | 'allowYn' | 'allowTitle'>[]
}

/**
 * 특정 약관 항목 갱신
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPAUTH/pages/336986609/02.
 * @param params
 * @param config
 */
export function updateTermsAgreement_v2(params: UpdateTermsAgreementRequestParams, config: AxiosRequestConfig = {}) {
  const { termsType = 'TMS01', termsAgreements } = params

  return frontmanAxios.post<Pick<GetTermsAgreementResData, 'resultCode'>>('/heimdall/heimdall-rmi/api/v1/user/$userKey/terms', {
    termsType,
    termsAgreements
  }, config)
}
