import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { GetEventJoinStatusRequestType, GetEventJoinStatusResponseType } from './types'

/**
 * 이벤트 참여 정보 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/FYJNOOBUUR/pages/31952876/API+-+06.
 * @param params
 * @param config
 */


function getEventJoinStatus(params: GetEventJoinStatusRequestType, config: AxiosRequestConfig = {}) {
  const { access_key, event_name } = params
  return frontmanAxios.get<GetEventJoinStatusResponseType>(`/event/event/get_applicant?access_key=${access_key}&event_name=${event_name}`, config)
}

export { getEventJoinStatus }
