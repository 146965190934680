import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'


/**
 * 내 차 관리 정보 삭제
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPDS/pages/456722338/DEV+STG
 * @param carProfileId
 * @param config
 */

function deleteCarMaintenance(carProfileId: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.delete<void>(`/driving-score/mycar/users/$userKey/cars/${carProfileId}/maintenance`, config)
}

export { deleteCarMaintenance }
