import { AxiosRequestConfig } from "axios";
import { frontmanAxios } from "../../axios";
import { CharanchaCarResData } from "./types";

/**
 * 최근 본 차량 리스트 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/572817800/API#2.-%ED%8B%B0%EB%A7%B5-%EC%97%B0%EB%8F%99%EC%9C%A0%EC%A0%80-%EC%B5%9C%EA%B7%BC-%EB%B3%B8-%EC%B0%A8%EB%9F%89-%EB%A6%AC%EC%8A%A4%ED%8A%B8-%EC%A1%B0%ED%9A%8C-API
 * @see https://dev-tmap-open-api.charancha.com/docs/user.html#_티맵_연동유저_최근_본_차량_리스트_조회
 * @param tmapKey // euk
 * @param config
 */

function getCharanchaRecentlyViewedCarList(tmapKey: string, config: AxiosRequestConfig = {}) {
  return frontmanAxios.get<CharanchaCarResData>(`/charancha/v1/users/-/recently-viewed-car?tmapKey=${tmapKey}`, {...config})
}

export { getCharanchaRecentlyViewedCarList }
