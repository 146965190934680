import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../../axios'
import template from 'lodash/template'
import { AdsTrackingParams } from './types'

/**
 * 광고 트래킹 api (노출, 클릭 이벤트)
 * @see https://tmobi.atlassian.net/wiki/spaces/ADP/pages/320045203/Tracking+API
 * @param params
 * @param config
 */

function getAdsTracking(params: AdsTrackingParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  const { url, materialId, orientation, vimpressionId } = params
  const adsTrackingUrl = template(url)({
    MATERIALID: materialId,
    ORIENTATION: orientation === 2 ? 'landscape' : 'portrait',
    VIMPRESSIONID: vimpressionId,
  })
  return frontmanAxios.get<void>(adsTrackingUrl, {
    ...restConfig,
    headers: {
      ...configHeaders,
      userkey: '$userKey',
    }
  })
}

export { getAdsTracking }
