import React, { forwardRef, TextareaHTMLAttributes } from 'react'
import { TextArea } from './styled-components'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const DevToolTextArea = forwardRef<HTMLTextAreaElement, Props>(function DevToolTextArea(props, ref) {
  const { rows = 4, ...attrs } = props
  return <TextArea rows={rows} ref={ref} {...attrs}/>
})

export { DevToolTextArea }
