import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = PropsWithChildren<{
  elementId?: string
}>

const defaultId = 'dialog-portal'

function DialogPortal(props: Props) {
  const { elementId = defaultId, children } = props
  const [, setMounted] = useState(false)
  const ref = useRef<Element>()

  useEffect(() => {
    let portalElement = document.querySelector(`#${elementId}`)
    if (!portalElement) {
      portalElement = document.createElement('div')
      portalElement.id = elementId
      document.body.appendChild(portalElement)
    }
    ref.current = portalElement
    setMounted(true)
  }, [])

  return ref.current ? createPortal(children, ref.current) : null
}

export { DialogPortal }
