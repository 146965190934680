import React, { RefObject } from 'react'
import { useCopyNodeText } from '../hooks'
import { DevToolButton } from './DevToolButton'

function DevToolCopyButton(props: { contentRef: RefObject<HTMLElement>; className?: string }) {
  const { className, contentRef } = props
  const handleClick = useCopyNodeText(contentRef)

  return (
    <DevToolButton
      className={className}
      onClick={handleClick}
    >복사</DevToolButton>
  )
}

export { DevToolCopyButton }
