import isUndefined from 'lodash/isUndefined'
import { UndefinableProps } from './types';

interface Options {
  parseJson?: boolean;
}

export type SearchParams<T extends any> = Record<string, string | undefined> & UndefinableProps<T>

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Location/search
 */
export function getSearchParams<T extends {}>(search: string = window.location.search,
  {
    parseJson = false,
  }: Options = {}
): SearchParams<T> {
  search = search[0] === '?' ? search.slice(1) : search

  const searchParams = {} as SearchParams<T>

  if (!search) {
    return searchParams
  }

  return search.split('&').reduce((map, token) => {
    const pair = token.split('=')
    const key = decodeURIComponent(pair[0]) as keyof SearchParams<T>
    let value
    if (isUndefined(pair[1])) {
      value = (void 0)
    } else {
      value = decodeURIComponent(pair[1])
      if (parseJson) {
        try {
          value = JSON.parse(value)
        } catch {}
      }
    }
    map[key] = value
    return map
  }, searchParams)
}
