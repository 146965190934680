import ReactBaseControl from '@/controls/ReactBaseControl';
import { Disable } from '@/types/CommonProps';
import clsx from 'clsx';
import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

type Props = Disable & {
    className?: string;
    style?: CSSProperties;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children?: ReactNode;
};

class ReactButtonControl extends React.PureComponent<Props> {
    public render(): ReactNode {
        const { className, style, onClick, disabled, children } = this.props;

        return (
            <ReactBaseControl defaultInteractive={true}>
                <div className='vsm-contents-wrap'>
                    <div className='vsm-button-frame'>
                        <button
                            className={clsx('vsm-button', className)}
                            style={style}
                            disabled={disabled}
                            onClick={onClick}
                        >
                            {children}
                        </button>
                    </div>
                </div>
            </ReactBaseControl>
        );
    }
}

export default ReactButtonControl;
