import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import { UbdsApiStatusResponse, MonthlyTripScoreInfos } from "./types";

export interface MonthlyTripScoreInfoRequestParams {
  reqMonth?: string | number;
  reqType?: 'T' | 'M';
}

/**
 * 월별 TRIP 합산점수 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPUBDS/pages/722174052/TRIP
 * @param params
 * @param config
 */

export interface MonthlyTripScoreInfoResData extends UbdsApiStatusResponse {
  data: MonthlyTripScoreInfos
}

function getMonthlyTripScoreInfo(params: MonthlyTripScoreInfoRequestParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.post<MonthlyTripScoreInfoResData>('/ubds/trip-report/v1/channel/ubds-rmi/RestApi/SafeDrivingService/findTripScoreMonthlyInfo', {
    reqMonth: params.reqMonth,
    reqType: params.reqType,
    userKey: '$userKey'
  }, {
    ...restConfig,
    headers: {
      'Body-Replace': true,
      ...configHeaders
    }
  })
}

export { getMonthlyTripScoreInfo }
