import { useBlockHistory, useDialogManager, useListenHistory } from '../hooks'
import { ReactRouterHistoryAction } from './types'

function useReactRouterDialogManager<State = any>() {
  return useDialogManager<ReactRouterHistoryAction, State>()
}

function useReactRouterListenHistory<State = any>() {
  return useListenHistory<ReactRouterHistoryAction, State>()
}

function useReactRouterBlockHistory<State = any>() {
  return useBlockHistory<ReactRouterHistoryAction, State>()
}

export {
  useReactRouterDialogManager as useDialogManager,
  useReactRouterListenHistory as useListenHistory,
  useReactRouterBlockHistory as useBlockHistory,
}
