import React, { ComponentType, PropsWithChildren, ReactNode, useMemo } from 'react'
import { DialogPortal } from './DialogPortal'
import { DialogItem } from './types'

interface Props {
  dialogPortalId?: string;
  dialogList: DialogItem[];
  dialogListWrapper?: ComponentType<PropsWithChildren<any>>;
}

function DialogContainer(props: Props) {
  const { dialogPortalId, dialogList, dialogListWrapper } = props
  const Wrapper = useMemo(() => {
    return dialogListWrapper || (({ children }: { children: ReactNode; }) => <>{children}</>)
  }, [dialogListWrapper]);

  return (
    <DialogPortal elementId={dialogPortalId}>
      <Wrapper>
        {dialogList.map((dialog) => {
          const { id, dialogParams } = dialog
          const { component: Component, props = {} } = dialogParams
          return <Component {...props} key={id}/>
        })}
      </Wrapper>
    </DialogPortal>
  )
}

export { DialogContainer }
