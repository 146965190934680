import { AxiosRequestConfig } from "axios";
import { frontmanAxios } from "../../axios";
import { EscApiBodyHeader, getKSTNow } from "../../../common";
import formatDateTime from "date-fns/format";

/**
 * OTP 발급
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/678592960/Otp
 * @param config
 */

export interface GenerateOtpCodeRes  {
  header: EscApiBodyHeader;
  resultCode: number;
  resultSubField: number;
  otpCode: string;
}

export interface GenerateOtpCodeParams {
  svcRequester?: string;
}
function generateOtpCode(params: GenerateOtpCodeParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  const requestTime = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss')

  return frontmanAxios.post<GenerateOtpCodeRes>('/esc/esc-channel/etc/auth/generateotpcode', {
    header: {
      reqTime: requestTime,
      svcRequester: params.svcRequester,
    }
  },{
    ...restConfig,
    headers: {
      UserKey: '$userKey',
      accept: 'application/json',
      ...configHeaders
    }
  })
}


export { generateOtpCode }