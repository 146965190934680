import { AxiosRequestConfig } from 'axios'
import { frontmanAxios } from '../../axios'
import { SingleTermsResType, TermsPrevSingleReqType } from './types'

/**
 * 약관 특정 버전 상세 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/FYJNOOBUUR/pages/31951107/M+ADMIN+-+-#MADMIN-%EC%82%AC%EC%9A%A9%EC%9E%90-%EC%95%BD%EA%B4%80-05.%EC%95%BD%EA%B4%80%ED%8A%B9%EC%A0%95%EB%B2%84%EC%A0%84%EC%83%81%EC%84%B8%EC%A1%B0%ED%9A%8C
 * @param params
 * @param config
 */

function getTermsDetailByApplyDate(params: Omit<TermsPrevSingleReqType, 'termsSeq'>, config: AxiosRequestConfig = {}) {
  const { appCode, termsCode, termsApplyDate } = params
  return frontmanAxios.get<SingleTermsResType>('/proxy/square-admin/user/api/terms/detail', {
    ...config,
    params: {
      appCode,
      termsCode,
      termsApplyDate,
    }
  })
}

export { getTermsDetailByApplyDate }
