import { frontmanAxios } from "../../../axios"
import { ShortenerUrlParams, ShortenerUrlRes } from "../../../../common";
import { AxiosRequestConfig } from "axios";

/**
 * url shortener
 * @see https://tmobi.atlassian.net/wiki/spaces/TMAPPOINEW/pages/453386536/SHORT+URL
 * @param params
 * @param config
 */

function shortenerUrl(params: ShortenerUrlParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  return frontmanAxios.post<ShortenerUrlRes>(
    `/surl/openapi/v1/urlshortener/shortener`,
    { ...params },
    {
      ...restConfig,
      headers: {
        'accept': 'application/json',
        ...configHeaders,
      }
    }
  )
}

export { shortenerUrl };