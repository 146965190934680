import { frontmanAxios } from '../../axios'
import { AxiosRequestConfig } from 'axios'
import formatDateTime from 'date-fns/format'
import { EscApiBody, getKSTNow } from '../../../common'

/**
 * 즐겨찾는 경로 동일 OD 경로 ID 조회
 * @see https://tmobi.atlassian.net/wiki/spaces/TSSQUARE/pages/244880786/08.#4.-%EC%A6%90%EA%B2%A8%EC%B0%BE%EB%8A%94-%EA%B2%BD%EB%A1%9C-%EB%8F%99%EC%9D%BC-OD-%EA%B2%BD%EB%A1%9C-ID-%EC%A1%B0%ED%9A%8C
 * @param params
 * @param config
 */

export interface SameOdRouteIdRequestParams {
  departCoordinate: string,
  destCoordinate: string,
  svcRequester?: string;
}

export interface SameOdRouteIdResData extends EscApiBody{
  routeId: string | null
}

function getSameOdRouteId(params: SameOdRouteIdRequestParams, config: AxiosRequestConfig = {}) {
  const { headers: configHeaders, ...restConfig } = config
  const requestTime = formatDateTime(getKSTNow(), 'yyyyMMddHHmmss')
  return frontmanAxios.post<SameOdRouteIdResData>('/esc/esc-channel/poi/favorite/findsameodrouteid', {
    departCoordinate: params.departCoordinate,
    destCoordinate: params.destCoordinate,
    header: {
      reqTime: requestTime,
      svcRequester: params.svcRequester,
    }
  }, {
    ...restConfig,
    headers: {
      UserKey: '$userKey',
      accept: 'application/json',
      ...configHeaders
    }
  })
}

export { getSameOdRouteId }